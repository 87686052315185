import React from 'react';

import PropTypes from 'prop-types';

import { List, Loader } from '@jvs-group/jvs-mairistem-composants';

const DataLoaderItem = ({ loading, children }) => (
  <List.Item>
    {loading
      ? (<List.Icon><Loader size="tiny" active inline /></List.Icon>)
      : (<List.Icon name="check circle" color="green" />)}
    <List.Content verticalAlign="middle">
      <List.Header>{children}</List.Header>
    </List.Content>
  </List.Item>
);

DataLoaderItem.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

DataLoaderItem.defaultProps = {
  loading: true,
  children: null,
};

export default DataLoaderItem;
