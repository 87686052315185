import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import { Checkbox, Dropdown } from '@jvs-group/jvs-mairistem-composants';

const CheckableDropdownItem = (props) => {
  const {
    disabled,
    group,
    header,
    onClick,
    selected,
    text,
    value,
    ...rest
  } = props;

  const classes = !_.isNil(group)
    ? 'grouped'
    : '';

  return (
    <Dropdown.Item
      id={value}
      group={group}
      className={classes}
      {...!disabled && { onClick }}
      {...!_.isNil(onClick) && { selected }}
      {...header && { as: Dropdown.Header }}
    >
      <Checkbox
        label={text}
        disabled={disabled}
        {...!_.isNil(onClick) && { checked: selected }}
        {...rest}
      />
    </Dropdown.Item>
  );
};

CheckableDropdownItem.propTypes = {
  disabled: PropTypes.bool,
  group: PropTypes.number,
  header: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  text: PropTypes.string,
  value: PropTypes.number,
};

CheckableDropdownItem.defaultProps = {
  disabled: null,
  group: null,
  header: null,
  onClick: null,
  selected: null,
  text: null,
  value: null,
};
export default CheckableDropdownItem;
