import React from 'react';

const DataLoaderContext = React.createContext({
  salleContextLoadings: {
    loadingSalles: true,
    loadingTypes: true,
    loadingTypesReservantSalle: true,
  },
  reservationContextLoadings: {
    loadingReservations: true,
    loadingStatuts: true,
    loadingTypesReservation: true,
  },
  reservantContextLoadings: {
    loadingTypesReservant: true,
    loadingReservants: true,
  },

  setSalleContextLoadings: () => null,
  setReservantContextLoadings: () => null,
  setReservationContextLoadings: () => null,
  setParametreContextLoadings: () => null,

  planningPreload: null,
  fullyLoaded: false,
  setErrors: () => null,
});

export default DataLoaderContext;
