import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
  Dimmer,
  Header,
  Label,
  Loader,
  Popup,
  Ref,
  Table,
} from '@jvs-group/jvs-mairistem-composants';

import { SalleDescription } from '../SalleDescription';
import { SemanticCOLORS } from '../../const/colors';

const DisponibiliteResultsTable = (props) => {
  const {
    loading,
    onSelect,
    salles,
    selectMessage,
    typesSalle,
  } = props;

  const [contextPopup, setContextPopup] = React.useState(null);

  const handleRef = (node) => {
    setContextPopup(node);
  };

  const handleSalleClick = (id) => () => {
    if (onSelect) {
      onSelect(id);
    }
  };

  const typesSallesColors = React.useMemo(() => _.map(
    typesSalle,
    (type, i) => ({ ...type, color: SemanticCOLORS[i % SemanticCOLORS.length] }),
  ), [typesSalle]);

  if (loading) {
    return (
      <>
        {selectMessage && (
          <div style={{ textAlign: 'right', paddingBottom: 10 }}>
            <Header as="h4">
              Sélectionner une salle pour ajouter une réservation
            </Header>
          </div>
        )}
        <div>
          <Table
            attached="top"
            verticalAlign="middle"
            size="small"
            style={{ borderBottom: 'none' }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={9}>
                  Salles disponibles
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>
                  Type de salle
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={1}>
                  Capacité
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>
                  Places assises
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </Table>
          <Dimmer.Dimmable
            as={Table}
            attached="bottom"
            verticalAlign="middle"
            size="small"
            selectable
          >
            <Table.Body>
              <Table.Row verticalAlign="middle" style={{ height: '80px' }}>
                <Table.Cell collapsing>
                  <Dimmer inverted active>
                    <Loader><span>Chargement...</span></Loader>
                  </Dimmer>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Dimmer.Dimmable>
        </div>
      </>
    );
  }

  return (
    <>
      {!_.isEmpty(salles) && selectMessage && (
        <div style={{ textAlign: 'right', paddingBottom: 10 }}>
          Sélectionner une salle pour ajouter une réservation
        </div>
      )}
      <Ref innerRef={handleRef}>
        <div
          style={{
            maxHeight: '20rem',
            overflowY: 'auto',
          }}
        >
          <Table
            verticalAlign="middle"
            size="small"
            selectable={!_.isEmpty(salles)}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Salles disponibles
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>
                  Type de salle
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={1}>
                  Capacité
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center" width={3}>
                  Places assises
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.isEmpty(salles) && (
                <Table.Row
                  key="emptyRow"
                  verticalAlign="middle"
                  style={{ height: 30 }}
                >
                  <Table.Cell style={{ fontWeight: 'bold' }}>
                    Aucune salle ne correspond aux critères sélectionnés
                  </Table.Cell>
                </Table.Row>
              )}
              {_.map(salles, (salle) => {
                const {
                  capaciteAccueil,
                  capaciteAccueilAssis,
                  description,
                  heureDebut,
                  heureFin,
                  identifiant,
                  identifiantTypesSalle,
                  nomSalle,
                  superficie,
                } = salle;

                const type = _.find(
                  typesSallesColors,
                  { identifiant: identifiantTypesSalle },
                );

                const row = (
                  <Table.Row
                    data-testid={`salle-${identifiant}`}
                    onClick={handleSalleClick(identifiant)}
                    key={`salle${identifiant}`}
                    verticalAlign="middle"
                    style={{ cursor: 'pointer' }}
                  >
                    <Table.Cell style={{ fontWeight: 'bold' }}>
                      {nomSalle}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <Label
                        size="small"
                        color={type.color || 'blue'}
                        content={type.libelle}
                      />
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {capaciteAccueil || '-'}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {capaciteAccueilAssis || '-'}
                    </Table.Cell>
                  </Table.Row>
                );

                return (
                  <Popup
                    style={{ width: 600 }}
                    content={(
                      <SalleDescription
                        identifiant={identifiant}
                        download={false}
                        description={description}
                        heureDebut={heureDebut}
                        heureFin={heureFin}
                        superficie={superficie}
                        placesTotales={capaciteAccueil}
                        placesAssises={capaciteAccueilAssis}
                      />
                    )}
                    trigger={row}
                    flowing
                    hoverable
                    pinned
                    position="top center"
                    basic
                    mouseEnterDelay={200}
                    mouseLeaveDelay={200}
                    context={contextPopup}
                  />
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </Ref>
    </>
  );
};

DisponibiliteResultsTable.propTypes = {
  loading: PropTypes.bool,
  selectMessage: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  salles: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  typesSalle: PropTypes.array,
  onSelect: PropTypes.func,
};

DisponibiliteResultsTable.defaultProps = {
  loading: null,
  salles: null,
  selectMessage: true,
  typesSalle: null,
  onSelect: null,
};

export default DisponibiliteResultsTable;
