/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import { Event, View } from '@jvs-group/jvs-mairistem-planning';
import {
  Icon,
  ListView,
  ListViewAction,
  PopupListView,
} from '@jvs-group/jvs-mairistem-composants';

import ListViewPictos from './ListViewPictos';

import {
  ParametreContext,
  PlanningContext,
  ReservantContext,
  ReservationContext,
  SalleContext,
} from '../../context';
import {
  COULEUR_BLEU_JVS,
} from '../../const/colors';
import { getReservationColor, getReservationTooltipText } from '../../utils';

const Creneau = (props) => {
  const {
    dateDebut: debutCreneau,
    reservations,
    partiel,
    ...rest
  } = props;

  const { salles } = React.useContext(SalleContext);
  const { reservants } = React.useContext(ReservantContext);
  const { statuts, typesReservation } = React.useContext(ReservationContext);
  const {
    view,
    reservationHandlers: { create, goTo },
  } = React.useContext(PlanningContext);
  const { typeCouleur, couleurParticulier } = React.useContext(ParametreContext);

  // Clic sur le bouton "Ajouter une réservation" de la popup
  const handleCreate = React.useCallback((e) => {
    const salle = _.find(
      salles,
      { identifiant: _.head(reservations)?.identifiantResource },
    );

    create(e, '', salle, true);
  }, [salles, reservations]);

  // Clic sur le titre d'une réservation pour aller sur sa fiche Webdev
  const handleRedirect = React.useCallback((e, reservation) => {
    goTo(e, reservation);
  }, [goTo]);

  const handleEventClick = React.useCallback((e) => {
    // Si on a qu'une réservation
    // Qu'elle n'est pas partielle OU qu'elle est partielle et qu'on est en vu jour
    // → On va sur le détail
    if (reservations.length === 1 && (!partiel || (view === View.DAY && partiel))) {
      goTo(e, _.head(reservations));
    }
  }, [reservations, goTo, view]);

  const handlePopupClick = React.useCallback((e) => {
    e.stopPropagation();
  }, []);

  // Background des réservations
  const background = partiel
    ? 'repeating-linear-gradient(45deg, #004a99, #004a99ba 10px, #ffffff 10px, #ffffff 20px)'
    : '#004a99';

  return (
    <Event
      {...rest}
      inverted
      style={{
        ...rest.style,
        background,
        height: '100%', // Pour que la réservation prenne toute la hauteur du créneau
      }}
      onClick={handleEventClick}
    >
      <PopupListView
        trigger={(<div style={{ height: '100%', width: '100%' }} />)}
        mouseEnterDelay={500}
        hoverable
        flowing
        onClick={handlePopupClick}
        content={(
          <ListView
            permanentView
            viewWidth={400}
            items={_.map(reservations, (reservation) => {
              const {
                identifiant,
                dateDebut,
                dateFin,
                salleIndisponible = true,
                complementInformation,
                identifiantReservant,
                identifiantTypeReservation,
                identifiantStatuts,
              } = reservation;

              const statut = _.find(statuts, { identifiant: identifiantStatuts });
              const type = _.find(typesReservation, { identifiant: identifiantTypeReservation });
              const reservant = _.find(reservants, { identifiant: identifiantReservant });

              const {
                nomClassement,
                typeReservant,
              } = reservant || {};

              const {
                libelle: libelleStatut,
                couleurFond: couleurStatut,
              } = statut || {};

              const {
                libelle: libelleTypeReservation,
                couleurPlanning: couleurTypeReservation,
              } = type || {};

              const startDate = dateDebut.format('D MMM');
              const startHour = dateDebut.format('HH:mm');
              const endDate = dateFin.format('D MMM');
              const endHour = dateFin.format('HH:mm');

              const isMaintenance = (salleIndisponible === 'Maintenance');

              // Si la réservation est une maintenance alors on a pas de nom de réservant
              const libelleReservant = isMaintenance
                ? 'Indisponible'
                : `Réservation faite par ${nomClassement}`;

              const color = getReservationColor(
                typeCouleur,
                couleurParticulier,
                couleurStatut,
                couleurTypeReservation,
                typeReservant,
              );

              const tooltip = getReservationTooltipText(
                typeCouleur,
                typeReservant,
                libelleStatut,
                libelleTypeReservation,
              );

              const title = (
                <a
                  style={{ cursor: 'pointer', color: COULEUR_BLEU_JVS }}
                  onClick={(e) => handleRedirect(e, reservation)}
                >
                  🔗
                  {' '}
                  {libelleReservant}
                </a>
              );

              return ({
                identifiant,
                title: (
                  <>
                    {isMaintenance && (
                      <Icon
                        name="warning sign"
                        size="small"
                        style={{ paddingRight: 5, marginTop: -2 }}
                        title="En maintenance"
                      />
                    )}
                    {isMaintenance
                      ? 'Maintenance'
                      : (
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          alignContent: 'center',
                        }}
                        >
                          {!_.isEmpty(color) && (
                            <div
                              title={tooltip}
                              style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: color,
                                marginRight: '5px',
                              }}
                            />
                          )}
                          {nomClassement}
                        </div>
                      )}
                  </>
                ),
                subtitle: _.isNil(dateDebut)
                  ? ''
                  : `Du ${startDate} à ${_.replace(startHour, ':', 'h')} au ${endDate} à ${_.replace(endHour, ':', 'h')}`,
                view: {
                  title,
                  descriptionTitle: 'Observation :',
                  description: complementInformation,
                  footer: (
                    <ListViewPictos
                      statut={_.upperFirst(statut?.libelle)}
                      type={_.upperFirst(type?.libelle)}
                      maintenance={isMaintenance}
                    />
                  ),
                },
              });
            })}
            action={(
              <ListViewAction
                color="blue"
                style={{
                  borderTopLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  borderTopRightRadius: 0,
                }}
                size="tiny"
                icon="add to calendar"
                content="Ajouter une réservation"
                onClick={handleCreate}
              />
            )}
          />
        )}
      />
    </Event>
  );
};

Creneau.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dateDebut: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  reservations: PropTypes.array,
  partiel: PropTypes.bool,
  onClick: PropTypes.func,
};
Creneau.defaultProps = {
  dateDebut: null,
  reservations: [],
  partiel: false,
  onClick: null,
};

export default Creneau;
