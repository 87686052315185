import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
  View,
  dayjs,
} from '@jvs-group/jvs-mairistem-planning';

import {
  getDayJsTimeMove,
  groupReservationsByCreneau,
  viewConstantToFrench,
  viewFrenchToConstant,
} from '../utils';
import {
  ApplicationContext,
  PlanningContext,
  ReservationContext,
  SalleContext,
} from '../context';
import Mode from '../const/mode';

const PlanningContextProvider = ({ children }) => {
  const [view, setView] = React.useState(View.WEEK);
  const [currentDate, setCurrentDate] = React.useState(dayjs());
  const [mode, setMode] = React.useState(Mode.HORIZONTAL);
  const [selectedSallesIds, setSelectedSallesIds] = React.useState([]);

  const {
    affichageDefaut: {
      date: defaultDate,
      mode: defaultMode,
      salles: defaultSallesIds,
      type: defaultView,
    },
    webdev,
  } = React.useContext(ApplicationContext);
  const { salles } = React.useContext(SalleContext);
  const {
    dayReservations,
    weekReservations,
    monthReservations,
    getReservationsOnGivenPeriod,
  } = React.useContext(ReservationContext);

  /** Clic sur JOUR / SEMAINE / MOIS */
  const handleViewChange = React.useCallback((nextView) => {
    setView(nextView);

    if (webdev) {
      if (process.env.NODE_ENV === 'development') {
        // on est en react
        alert(`vChangeTypeVue | vChangeSelectedSalles : ${nextView} | ${_.join(selectedSallesIds)}`);
      } else {
        vChangeSelectedSalles(selectedSallesIds); // eslint-disable-line
        vChangeTypeVue(viewConstantToFrench(nextView)); // eslint-disable-line
      }
    }

    if (defaultMode !== Mode.VERTICAL) {
      getReservationsOnGivenPeriod(nextView, currentDate, 2);
    }
  }, [
    getReservationsOnGivenPeriod,
    currentDate,
    defaultMode,
    webdev,
    selectedSallesIds,
  ]);

  /** Clic sur un jour dans le calendrier ou sur les entêtes */
  const handleDateChange = React.useCallback((nextDate, switchToDayView = false) => {
    setCurrentDate(nextDate);

    if (switchToDayView) {
      // Ici forcément planning react
      setView(View.DAY);
      getReservationsOnGivenPeriod(View.DAY, nextDate, 2);
    } else {
      if (webdev) {
        if (process.env.NODE_ENV === 'development') {
          // on est en react
          alert(`vGoToDate : ${nextDate.format('YYYY-MM-DD')}`);
        } else {
          vGoToDate(nextDate.format('YYYY-MM-DD')); // eslint-disable-line
        }
      }

      if (defaultMode !== Mode.VERTICAL) {
        getReservationsOnGivenPeriod(view, nextDate, 2);
      }
    }
  }, [getReservationsOnGivenPeriod, view, defaultMode, webdev]);

  /** Clic sur période suivante */
  const handleNextDate = React.useCallback(() => {
    const nextDate = currentDate.add(...getDayJsTimeMove(view));
    setCurrentDate(nextDate);

    if (webdev) {
      if (process.env.NODE_ENV === 'development') {
        // on est en react
        alert(`vChangePeriode : ${nextDate.format('YYYY-MM-DD')}`);
      } else {
        vChangePeriode(nextDate.format('YYYY-MM-DD')); // eslint-disable-line
      }
    }

    if (defaultMode !== Mode.VERTICAL) {
      getReservationsOnGivenPeriod(view, nextDate, 2);
    }
  }, [currentDate, view, getReservationsOnGivenPeriod, defaultMode, webdev]);

  /** Clic sur période précédente */
  const handlePreviousDate = React.useCallback(() => {
    const nextDate = currentDate.subtract(...getDayJsTimeMove(view));
    setCurrentDate(nextDate);

    if (webdev) {
      if (process.env.NODE_ENV === 'development') {
        // on est en react
        alert(`vChangePeriode : ${nextDate.format('YYYY-MM-DD')}`);
      } else {
        vChangePeriode(nextDate.format('YYYY-MM-DD')); // eslint-disable-line
      }
    }

    if (defaultMode !== Mode.VERTICAL) {
      getReservationsOnGivenPeriod(view, nextDate, 2);
    }
  }, [getReservationsOnGivenPeriod, currentDate, view, defaultMode, webdev]);

  /** Sélection des salles dans la dropdown. */
  const handleSallesSelect = React.useCallback((selection) => {
    // selection = liste des salles par type

    // Récupération des identifiants de salles uniquement sans les types
    let identifiantsSelectedSalles = [];

    _.map(selection, ({
      group,
      items,
      value,
      selected,
    }) => {
      if (!group && selected) {
        identifiantsSelectedSalles = [...identifiantsSelectedSalles, value];
      }

      identifiantsSelectedSalles = [
        ...identifiantsSelectedSalles,
        ..._.map(
          _.filter(items, ({ selected: isSelected }) => isSelected),
          'value',
        ),
      ];
    });

    let nextMode = mode;
    // on prend 5 salles en vue jour, sinon 1
    const maxSelectedVertical = view === View.DAY ? 5 : 1;

    // Si on est en vertical et qu'on dépasse le max autorisé dans ce mode d'affichage
    if (mode === Mode.VERTICAL && _.size(identifiantsSelectedSalles) > maxSelectedVertical) {
      nextMode = Mode.HORIZONTAL;
    }

    if (webdev) {
      if (process.env.NODE_ENV === 'development') {
        // on est en react
        alert(`vChangeSelectedSalles | vChangeModeAffichage : ${identifiantsSelectedSalles} | ${nextMode}`);
      } else {
        vChangeSelectedSalles(identifiantsSelectedSalles); // eslint-disable-line
        vChangeModeAffichage(nextMode) // eslint-disable-line
      }
    }

    setSelectedSallesIds(identifiantsSelectedSalles);
    setMode(nextMode);
  }, [mode, webdev, view]);

  /** Clic sur une réservation */
  const handleReservationClick = React.useCallback((e, { identifiant, dateDebut }) => {
    // On est en react, la méthode webdev n'existe pas
    if (process.env.NODE_ENV === 'development') {
      alert(`vAfficheReservation : ${identifiant} | ${dayjs(dateDebut).format('YYYY-MM-DD')}`);
    } else { // En webdev on ouvre la fiche réservation
      vAfficheReservation(identifiant, dayjs(dateDebut).format('YYYY-MM-DD')); // eslint-disable-line
    }
  }, [currentDate, webdev, defaultMode]);

  /** Créer une réservation via la fiche webdev */
  const handleReservationCreate = React.useCallback((e, date, resource, dateless) => {
    // L'heure de fin par défaut de la réservation
    // En vue jour, on ajoute 15min au début du créneau
    // En vue semaine, on ajoute 11h59m59s soit 43199 secondes au début du créneau
    // En vue mois, on se place sur la fin de journée
    const dateEnd = dateless ? ''
      : view === View.DAY
        ? date.add(30, 'minute')
        : view === View.WEEK
          ? date.add(43199, 'second')
          : date.endOf('day');

    if (webdev) {
      if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-alert
        alert(`Création d'une réservation depuis le planning pour la salle : ${resource?.nomSalle} 
            le ${dateless ? '/' : date.format('YYYY-MM-DD HH:mm')} 
            au ${dateless ? '/' : dateEnd.format('YYYY-MM-DD HH:mm')}`);
      } else {
        vAjouteReservation( // eslint-disable-line
          dateless ? '' : date.format('YYYY-MM-DD HH:mm'),
          dateless ? '' : dateEnd.format('YYYY-MM-DD HH:mm'),
          resource,
          true,
        );
      }
    }
  }, [webdev, view]);

  /** Changement de mode horizontal (full react) ou vertical (mix react / webdev) */
  const handleModeChange = React.useCallback((nextMode) => {
    let nextSelectedResources = [...selectedSallesIds];

    let maxSelectedVertical;

    if (nextMode === Mode.VERTICAL) {
      // Si on passe en vertical
      if (view === View.DAY) {
        // Vertical jour, on autorise maximumSelectedVertical
        maxSelectedVertical = 5;
      } else {
        // Vertical semaine / mois, on autorise qu'une salle
        maxSelectedVertical = 1;
      }
    } else {
      // Si on passe en horizontal, pas de limite
      maxSelectedVertical = 9999999;
    }

    // Si on passe au mode vertical et qu'on a trop de salle sélectionnées
    // On garde en sélection uniquement la première de la liste
    if (_.size(selectedSallesIds) > maxSelectedVertical) {
      nextSelectedResources = [_.head(nextSelectedResources)];
    }

    setMode(nextMode);
    setSelectedSallesIds(nextSelectedResources);

    if (webdev) {
      if (process.env.NODE_ENV === 'development') {
        // on est en react
        alert(`vChangeSelectedSalles | vChangeModeAffichage: ${nextSelectedResources} | ${dayjs(nextMode).format('YYYY-MM-DD')}`);
      } else {
        vChangeSelectedSalles(nextSelectedResources); // eslint-disable-line
        vChangeModeAffichage(nextMode); // eslint-disable-line
      }
    }
  }, [webdev, selectedSallesIds]);

  // Lorsqu'on reçoit les salles, on les sélectionne toutes par défaut
  // Sauf en mode webdev, on fait attention à charger que celles précédemment cochées
  React.useEffect(() => {
    if (!_.isEmpty(salles)) {
      if (!_.isEmpty(defaultSallesIds)) {
        setSelectedSallesIds(defaultSallesIds);
      } else {
        setSelectedSallesIds(_.map(salles, 'identifiant'));
      }
    }
  }, [defaultSallesIds, salles]);

  // On initialise la date avec la date donnée par webdev si présente
  React.useEffect(() => {
    if (!_.isEmpty(defaultDate)) {
      setCurrentDate(dayjs(defaultDate));
    }
  }, [defaultDate]);

  // On initialise la view avec la view donnée par webdev si présente
  React.useEffect(() => {
    if (!_.isEmpty(defaultView)) {
      if (_.includes([View.DAY, View.WEEK, View.MONTH], viewFrenchToConstant(defaultView))) {
        setView(viewFrenchToConstant(defaultView));
      }
    }
  }, [defaultView]);

  // On initialise le mode avec le mode donné par webdev si présente
  React.useEffect(() => {
    if (!_.isEmpty(defaultMode)) {
      setMode(defaultMode);
    }
  }, [defaultMode]);

  // Liste des réservations affichées à l'écran uniquement
  const reservationsOfView = React.useMemo(() => {
    let reservations;

    if (view === View.DAY) {
      reservations = dayReservations?.[currentDate.dayOfYear()];
    }
    if (view === View.WEEK) {
      reservations = weekReservations?.[currentDate.week()];
    }
    if (view === View.MONTH) {
      reservations = monthReservations?.[currentDate.month() + 1];
    }

    return groupReservationsByCreneau(view, currentDate, reservations);
  }, [currentDate, view, dayReservations, weekReservations, monthReservations]);

  const contextValue = React.useMemo(
    () => ({
      view,
      viewHandlers: {
        changeToView: handleViewChange,
        changeToMode: handleModeChange,
      },
      mode,
      reservationsOfView,
      reservationHandlers: {
        goTo: handleReservationClick,
        create: handleReservationCreate,
      },
      currentDate,
      dateHandlers: {
        goTo: handleDateChange,
        goForward: handleNextDate,
        goBackward: handlePreviousDate,
      },
      // sortBy obligé sinon l'ordre des groupes peut changer visuellement
      selectedSalles: _.sortBy(_.filter(
        salles,
        ({ identifiant }) => _.includes(selectedSallesIds, identifiant),
      ), ['libelleTypesSalle', 'nomSalle']),
      selectedSallesHandlers: {
        selectMany: handleSallesSelect,
      },
    }),
    [
      view,
      mode,
      reservationsOfView,
      currentDate,
      salles,
      selectedSallesIds,
      handleViewChange,
      handleModeChange,
      handleDateChange,
      handleNextDate,
      handlePreviousDate,
    ],
  );

  return (
    <PlanningContext.Provider value={contextValue}>
      {children}
    </PlanningContext.Provider>
  );
};

PlanningContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PlanningContextProvider;
