import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import './style.less';
// eslint-disable-next-line import/no-unresolved
import '@jvs-group/jvs-mairistem-styles';

ReactDOM.createRoot(document.getElementById('globalContainer'))
  .render(
    <App />,
  );
