import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
  Header,
} from '@jvs-group/jvs-mairistem-composants';

import PlacesAssisesSvg from '../../assets/placeassisesvert.svg';
import CapaciteSvg from '../../assets/capacitevert.svg';
import HorlogeSvg from '../../assets/clock.svg';
import Superficie from '../../assets/superficie.png';

const SalleDescriptionPictos = (props) => {
  const {
    superficie,
    heureDebut,
    heureFin,
    placesAssises,
    placesTotales,
  } = props;

  const renderSuperficie = React.useMemo(() => {
    const libelle = !superficie || superficie === 0
      ? '-'
      : `${superficie} m²`;

    return (
      <div>
        <div style={{
          marginTop: -4,
          paddingBottom: 4,
        }}
        >
          <img style={{ height: 18.5 }} src={Superficie} alt="Superficie" />
        </div>
        <Header
          as="h4"
          style={{
            margin: 0,
          }}
        >
          {libelle}
        </Header>
      </div>
    );
  }, [superficie]);

  const renderPlacesTotales = React.useMemo(() => {
    const libelle = !placesTotales || placesTotales === 0
      ? '-'
      : placesTotales + (placesTotales === 1 ? ' personne' : ' personnes');

    return (
      <div>
        <div>
          <img src={CapaciteSvg} alt="Capacité" style={{ height: 17 }} />
        </div>
        <Header
          as="h4"
          style={{
            margin: 0,
          }}
        >
          {libelle}
        </Header>
      </div>
    );
  }, [placesTotales]);

  const renderPlacesAssises = React.useMemo(() => {
    const libelle = !placesAssises
      ? '-'
      : placesAssises + (placesAssises <= 1 ? ' place assise' : ' places assises');

    return (
      <div>
        <div>
          <img src={PlacesAssisesSvg} alt="Places assises" style={{ height: 17 }} />
        </div>
        <Header
          as="h4"
          style={{
            margin: 0,
          }}
        >
          {libelle}
        </Header>
      </div>
    );
  }, [placesAssises]);

  const renderHoraires = React.useMemo(() => {
    const libelle = _.isEmpty(heureDebut) && _.isEmpty(heureFin)
      ? '-'
      : `${heureDebut} - ${heureFin}`;

    return (
      <div>
        <div>
          <img src={HorlogeSvg} alt="Heure" style={{ height: 17 }} />
        </div>
        <Header
          as="h4"
          style={{
            margin: 0,
          }}
        >
          {libelle}
        </Header>
      </div>
    );
  }, [heureDebut, heureFin]);

  return (
    <>
      {renderHoraires}
      {renderSuperficie}
      {renderPlacesTotales}
      {renderPlacesAssises}
    </>
  );
};

SalleDescriptionPictos.propTypes = {
  heureDebut: PropTypes.string,
  heureFin: PropTypes.string,
  superficie: PropTypes.number,
  placesAssises: PropTypes.number,
  placesTotales: PropTypes.number,
};

SalleDescriptionPictos.defaultProps = {
  heureDebut: null,
  heureFin: null,
  superficie: null,
  placesAssises: null,
  placesTotales: null,
};

export default SalleDescriptionPictos;
