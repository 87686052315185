import _ from 'lodash';
import React from 'react';

import { PlanningContext, SalleContext } from '../context';
import { CheckableDropdown } from './CheckableDropdown';

/**
 * Sélecteur de salle pour le planning
 */
const SalleSelect = () => {
  const { sallesParType } = React.useContext(SalleContext);
  const {
    selectedSalles,
    selectedSallesHandlers: { selectMany },
  } = React.useContext(PlanningContext);

  const handleOptionSelect = React.useCallback((data) => {
    if (selectMany) {
      selectMany(data);
    }
  }, [selectMany]);

  const formatSalleToOption = React.useCallback((data, selected) => ({
    key: `salle@${data.identifiant}`,
    value: data.identifiant,
    text: data.libelle,
    selected,
  }), []);

  const formatTypeToOption = React.useCallback((data, selected) => ({
    key: `type@${data.identifiant}`,
    value: data.identifiant,
    text: data.libelle,
    group: true,
    selected,
  }), []);

  // Formate les salles qu'on reçoit pour le sélecteur
  const options = React.useMemo(() => _.map(sallesParType, (type) => ({
    ...formatTypeToOption(
      type,
      // Le groupe est sélectionné si tous les items sont dans le tableau par défaut
      _.every(type?.salles, ({ identifiant }) => _.includes(_.map(selectedSalles, 'identifiant'), identifiant)),
    ),
    items: _.map(
      type.salles,
      (salle) => formatSalleToOption(
        salle,
        _.includes(_.map(selectedSalles, 'identifiant'), salle.identifiant),
      ),
    ),
  })), [formatSalleToOption, formatTypeToOption, sallesParType, selectedSalles]);

  return (
    <CheckableDropdown
      onOptionSelect={handleOptionSelect}
      options={options}
      placeholder="Sélectionner une salle"
      triggerStyle={{ width: 250.5 }}
    />
  );
};

export default SalleSelect;
