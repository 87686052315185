import _ from 'lodash';
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import Axios from 'axios';

import { dayjs } from '@jvs-group/jvs-mairistem-planning';

import { SalleContext, ApplicationContext, DataLoaderContext } from '../../context';
import {
  getSalles,
  getSallesByEntite,
  getTypesReservantSalle,
  getTypesSalle,
} from '../../routes';

const SalleContextProvider = ({ children }) => {
  const [salles, setSalles] = React.useState([]);
  const [typesSalle, setTypesSalle] = React.useState([]);
  const [typesReservantSalle, setTypesReservantSalle] = React.useState([]);

  const {
    salles: sallesDisponibles,
    baseUrl,
    identifiantEntite,
  } = React.useContext(ApplicationContext);
  const {
    salleContextLoadings: {
      loadingSalles,
      loadingTypes,
      loadingTypesReservantSalle,
    },
    setSalleContextLoadings,
    planningPreload,
    setErrors,
  } = React.useContext(DataLoaderContext);

  // Récupération des données préchargées s'il y en a
  useEffect(() => {
    if (!_.isNil(planningPreload)) {
      setSalles(planningPreload.salles);
      setTypesSalle(planningPreload.typesSalle);
      setTypesReservantSalle(planningPreload.typesReservantSalle);
      setSalleContextLoadings({
        loadingSalles: false,
        loadingTypes: false,
        loadingTypesReservantSalle: false,
      });
    }
  }, [planningPreload]);

  // Récupération des salles
  useEffect(() => {
    if (!loadingTypes) {
      const route = _.isEmpty(sallesDisponibles)
        ? getSallesByEntite(baseUrl, identifiantEntite)
        : getSalles(baseUrl, sallesDisponibles);

      Axios.get(route)
        .then(({ data }) => {
          // tri par ordre alphabétique des salles
          // avec le libelle de son type inclu et d'autres infos
          setSalles(_.sortBy(_.map(_.isEmpty(sallesDisponibles) ? data : data.data, (salle) => {
            const {
              identifiantTypesSalle,
              jourOuHeure,
              nombreJourReserve,
              dateMaximumReservation,
            } = salle;

            let finDelai;
            switch (jourOuHeure) {
              case 1:
                finDelai = dayjs().add(nombreJourReserve, 'days');
                break;
              case 2:
                finDelai = dayjs().add(nombreJourReserve, 'hours');
                break;

              default:
                finDelai = dayjs();
                break;
            }

            // Déformatage de la dateMax
            let dateMax;
            const typePeriode = dateMaximumReservation.split('|')[0];
            if (typePeriode === 'DA') {
              dateMax = dayjs(dateMaximumReservation.split('|')[1]);
            } else {
              const nombre = dateMaximumReservation.split('|')[1];
              switch (typePeriode) {
                case 'SE':
                  dateMax = dayjs().add(nombre, 'weeks');
                  break;
                case 'MO':
                  dateMax = dayjs().add(nombre, 'months');
                  break;
                case 'AN':
                  dateMax = dayjs().add(nombre, 'years');
                  break;
                default:
                  break;
              }
            }

            // Sinon ça initialise avec les minutes / secondes réèlles
            // et ça pose problème plus tard lors des comparaisons
            if (!_.isNil(dateMax)) { dateMax = dateMax.minute(0).second(0); }
            if (!_.isNil(finDelai)) { finDelai = finDelai.minute(0).second(0); }

            return ({
              ...salle,
              libelleTypesSalle: _.find(typesSalle, { identifiant: identifiantTypesSalle })?.libelle ?? '',
              dateMaximumReservation: dateMax,
              finDelai,
            });
          }), 'nomSalle'));
        }).catch(() => {
          setErrors((prev) => ({ ...prev, salles: 'Le chargement des salles a échoué' }));
        }).finally(() => {
          setSalleContextLoadings((prev) => ({ ...prev, loadingSalles: false }));
        });
    }
  }, [
    typesSalle,
    loadingTypes,
    sallesDisponibles,
    baseUrl,
    identifiantEntite,
  ]);

  // Récupération des types de salle
  useEffect(() => {
    Axios.get(getTypesSalle(baseUrl))
      .then(({ data: { data } }) => {
        setTypesSalle(_.sortBy(data, 'libelle'));
      }).catch(() => {
        setErrors((prev) => ({ ...prev, typesSalle: 'Le chargement des types de salle a échoué' }));
      }).finally(() => {
        setSalleContextLoadings((prev) => ({ ...prev, loadingTypes: false }));
      });
  }, [
    baseUrl,
  ]);

  // Récupération des types de réservant des salles
  useEffect(() => {
    Axios.get(getTypesReservantSalle(baseUrl, identifiantEntite))
      .then(({ data }) => {
        setTypesReservantSalle(data);
      }).catch(() => {
        setErrors((prev) => ({ ...prev, typesReservantSalle: 'Le chargement types de réservant des salles a échoué' }));
      }).finally(() => {
        setSalleContextLoadings((prev) => ({ ...prev, loadingTypesReservantSalle: false }));
      });
  }, [
    identifiantEntite,
    baseUrl,
  ]);

  const sallesParType = React.useMemo(() => {
    if (!_.isEmpty(typesSalle) && !_.isEmpty(salles)) {
      // On retourne un tableau en parcourant les types et les salles
      // où chaque élément est un type de salle simplifié, avec ses salles.
      // Exemple d'un élément :
      //    {
      //      identifiant: 1, // id du type
      //      libelle: 'Salle de réunion',
      //      salles: [
      //        {
      //          identifiant: 1, // id de la salle
      //          libelle: 'Salle du coin au fond à gauche'
      //        },
      //        {
      //          identifiant: 2,
      //          libelle: 'Salle du coin au fond à droite'
      //        }
      //      ]
      //    }
      return _.map(typesSalle, ({ identifiant, libelle }) => ({
        identifiant,
        libelle,
        salles: _.map(
          _.filter(salles, { identifiantTypesSalle: identifiant }),
          (salle) => ({
            identifiant: salle.identifiant,
            libelle: salle.nomSalle,
          }),
        ),
      }));
    }

    return null;
  }, [salles, typesSalle]); // sale type ? :(

  const contextValue = React.useMemo(
    () => ({
      salles,
      typesSalle,
      typesReservantSalle,
      sallesParType,
    }),
    [
      salles,
      typesSalle,
      typesReservantSalle,
      sallesParType,
    ],
  );

  return (
    <SalleContext.Provider value={contextValue}>
      {!loadingSalles && !loadingTypes && !loadingTypesReservantSalle && children}
    </SalleContext.Provider>
  );
};

SalleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SalleContextProvider;
