import _ from 'lodash';
import React from 'react';

import {
  Button,
  Grid,
  Icon,
  Popup,
  Segment,
} from '@jvs-group/jvs-mairistem-composants';
import { View } from '@jvs-group/jvs-mairistem-planning';

import PlanningHorizontal from '../assets/planning-horizontal.jpg';
import PlanningVertical from '../assets/planning-vertical.jpg';
import { PlanningContext } from '../context';
import Mode from '../const/mode';

const ModeHandler = () => {
  const { mode, view, viewHandlers: { changeToMode } } = React.useContext(PlanningContext);
  const { selectedSalles } = React.useContext(PlanningContext);

  const handleClick = React.useCallback((value) => () => {
    changeToMode(value);
  }, [changeToMode]);

  const disabled = React.useMemo(
    () => view === View.DAY
      && _.size(selectedSalles) > 5,
    [view, selectedSalles],
  );

  return (
    <>
      <Popup
        position="bottom right"
        style={{ padding: 0 }}
        flowing
        mouseEnterDelay={1000}
        {...!disabled && { open: false }}
        content={(
          <Grid padded>
            <Grid.Row style={{ flexWrap: 'nowrap', padding: 0 }}>
              <Grid.Column
                as={Segment}
                textAlign="center"
                style={{
                  padding: '1em 0',
                  marginRight: 0,
                  marginLeft: 0,
                  borderRadius: '4px 0 0 4px',
                  width: '22px',
                }}
                color="red"
              >
                <Icon
                  fitted
                  name="exclamation"
                  style={{ fontStyle: 'italic' }}
                />
              </Grid.Column>
              <Grid.Column
                as={Segment}
                verticalAlign="middle"
                basic
                width={16}
                style={{ padding: 0 }}
              >
                <Segment
                  basic
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginBottom: 5,
                    fontWeight: 'bold',
                  }}
                >
                  Nombre de salle sélectionnées maximum atteint
                </Segment>
                <Segment
                  basic
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0,
                  }}
                >
                  <i>
                    Dans ce mode d&apos;affichage, 5 salles au maximum peuvent être affichées
                  </i>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        trigger={(
          // span utile sinon popup apparaît pas à cause de Button disabled
          <span>
            <Button
              // disabled={disabled}
              active={mode === Mode.VERTICAL}
              onClick={handleClick(Mode.VERTICAL)}
              color="blue"
              inverted
              attached="left"
              style={{ height: '2.7rem', padding: 6, marginLeft: 20 }}
            >
              <img
                src={PlanningVertical}
                alt="vertical"
              />
            </Button>
          </span>
        )}
      />
      <Button
        disabled={disabled}
        active={mode === Mode.HORIZONTAL}
        onClick={handleClick(Mode.HORIZONTAL)}
        color="blue"
        inverted
        attached="right"
        style={{ height: '2.7rem', padding: 6 }}
      >
        <img
          src={PlanningHorizontal}
          alt="horizontal"
        />
      </Button>
      {/* <Popup
        position="bottom center"
        flowing
        mouseEnterDelay={1000}
        style={{ padding: 0 }}
        {...!disabled && { open: false }}
        content={(
          <Grid padded>
            <Grid.Row style={{ flexWrap: 'nowrap' }}>
              <Grid.Column
                as={Segment}
                textAlign="center"
                style={{
                  padding: '1em 0',
                  marginRight: 0,
                  marginLeft: 0,
                  borderRadius: '4px 0 0 4px',
                  width: '22px',
                }}
                color="red"
              >
                <Icon
                  fitted
                  name="exclamation"
                  style={{ fontStyle: 'italic' }}
                />
              </Grid.Column>
              <Grid.Column
                as={Segment}
                verticalAlign="middle"
                basic
                width={16}
                style={{ padding: 0 }}
              >
                <Segment
                  basic
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginBottom: 5,
                    fontWeight: 'bold',
                  }}
                >
                  Nombre de salle sélectionnées maximum atteint
                </Segment>
                <Segment
                  basic
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: 0,
                  }}
                >
                  <i>
                    Dans ce mode d&pos;affichage,
                    {' '}
                    {maximumSelectedVertical}
                    {' '}
                    salles au maximum peuvent être affichées
                  </i>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        trigger={(
          <span>
            <Button
              {...mode === 'vertical' ? { className: 'btnSelected' } : null}
              disabled={disabled}
              onClick={handleClick('vertical')}
              style={{
                alignItems: 'center',
                backgroundColor: 'transparent',
                border: '2px solid black',
                borderRight: 'none',
                borderRadius: '5px 0 0 5px',
                display: 'flex',
                height: '2.7rem',
                justifyContent: 'center',
                padding: '0.5em',
                marginLeft: '0.5rem',
              }}
              content={(
                <img
                  alt="planning-vertical"
                  style={{ height: 26 }}
                  src={PlanningVertical}
                />
              )}
            />
          </span>
        )}
      />
      <Button
        {...mode === 'horizontal' ? { className: 'btnSelected' } : null}
        onClick={handleClick('horizontal')}
        style={{
          alignItems: 'center',
          backgroundColor: 'transparent',
          border: '2px solid black',
          borderLeft: 'none',
          borderRadius: '0 5px 5px 0',
          display: 'flex',
          height: '2.7rem',
          justifyContent: 'center',
          padding: '0.5em',
          marginRight: '0.5rem',
        }}
        content={(
          <img
            alt="planning-horizontal"
            style={{ height: 26 }}
            src={PlanningHorizontal}
          />
        )}
      /> */}
    </>
  );
};

export default ModeHandler;
