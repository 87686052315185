import React from 'react';

import { View, dayjs } from '@jvs-group/jvs-mairistem-planning';

import Button from './Button';
import { PlanningContext } from '../context';

const ViewHandler = () => {
  const {
    view,
    viewHandlers: { changeToView },
    currentDate,
    dateHandlers: {
      goTo,
    },
  } = React.useContext(PlanningContext);

  const today = React.useMemo(() => dayjs(), []);

  return (
    <>
      <Button
        {...(currentDate?.isSame(today, 'day')) ? {
          disabled: true,
        } : {
          color: 'blue',
          inverted: true,
        }}
        size="large"
        onClick={() => { goTo(today); }}
      >
        Aujourd&nbsp;hui
      </Button>
      <Button
        active={view === View.DAY}
        color="blue"
        inverted
        size="large"
        onClick={() => changeToView(View.DAY)}
      >
        Jour
      </Button>
      <Button
        active={view === View.WEEK}
        color="blue"
        inverted
        size="large"
        onClick={() => changeToView(View.WEEK)}
      >
        Semaine
      </Button>
      <Button
        active={view === View.MONTH}
        color="blue"
        inverted
        size="large"
        onClick={() => changeToView(View.MONTH)}
      >
        Mois
      </Button>

    </>
  );
};

export default ViewHandler;
