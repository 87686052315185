import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
  Icon,
  Table,
  Checkbox,
  Label,
  Message,
} from '@jvs-group/jvs-mairistem-composants';

import { SemanticCOLORS } from '../../const/colors';

const DocumentTable = (props) => {
  const {
    items,
    selectedItems,
    typesSalles,
    onItemSelect,
    onAllSelect,
  } = props;

  // Pour savoir si tous les items sont selectionnés
  const allSelected = React.useMemo(
    () => _.size(selectedItems) === _.size(items),
    [items, selectedItems],
  );

  const typesSallesColors = React.useMemo(() => _.map(
    typesSalles,
    (type, i) => ({ color: SemanticCOLORS[i % SemanticCOLORS.length], ...type }),
  ), [typesSalles]);

  if (_.isEmpty(items)) {
    return (
      <Message style={{ textAlign: 'center' }}>
        Aucune salle n&apos;a de document à télecharger
      </Message>
    );
  }

  return (
    <>
      <Table size="small" attached="top">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              <Checkbox
                checked={allSelected}
                onChange={onAllSelect}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              Nom de la salle
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Type de salle
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Document(s)
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(items, (item) => {
            const {
              identifiant: identifiantSalle,
              identifiantTypesSalle,
              nomSalle,
              libelleTypesSalle,
              documents,
            } = item;

            const selected = _.includes(selectedItems, identifiantSalle);

            return (
              <Table.Row
                key={identifiantSalle}
                verticalAlign="middle"
              >
                <Table.Cell textAlign="center">
                  <Checkbox
                    name={identifiantSalle.toString()}
                    checked={selected}
                    onChange={onItemSelect}
                  />
                </Table.Cell>
                <Table.Cell style={{ fontWeight: 'bold' }}>
                  {nomSalle}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Label
                    size="small"
                    color={_.find(
                      typesSallesColors,
                      { identifiant: identifiantTypesSalle },
                    )?.color ?? 'blue'}
                    content={libelleTypesSalle}
                  />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Icon name="file" />
                  <span>
                    {_.size(documents)}
                  </span>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Message
        info
        attached="bottom"
        size="tiny"
      >
        <Icon name="info" />
        Uniquement les salles avec des documents apparaîtront dans la liste
      </Message>
    </>
  );
};

DocumentTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.arrayOf(PropTypes.object),
  selectedItems: PropTypes.arrayOf(PropTypes.number),
  // eslint-disable-next-line react/forbid-prop-types
  typesSalles: PropTypes.arrayOf(PropTypes.object),
  onAllSelect: PropTypes.func,
  onItemSelect: PropTypes.func,
};

DocumentTable.defaultProps = {
  items: [],
  selectedItems: [],
  typesSalles: [],
  onAllSelect: null,
  onItemSelect: null,
};

export default DocumentTable;
