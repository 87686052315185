import React from 'react';

const ApplicationContext = React.createContext({
  // Infos webdev
  affichageDefaut: {}, // Type d'affichage
  identifiantEntite: 0,
  loading: true,
  modeTest: false,
  publication: null, // Token du header X-Database
  preprod: false,
  salles: '', // Salle(s) disponible(s) dans le planning
  webdev: true,
  // Autres infos
  baseUrl: 'https://planning-gds.jvsonline.fr',
});

export default ApplicationContext;
