import _ from 'lodash';
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import Axios from 'axios';

import { ApplicationContext, DataLoaderContext, ParametreContext } from '../../context';
import {
  getParametres,
} from '../../routes';

const ParametreContextProvider = ({ children }) => {
  const [typeCouleur, setTypeCouleur] = React.useState([]);
  const [couleurParticulier, setCouleurParticulier] = React.useState([]);

  const {
    identifiantEntite,
    baseUrl,
  } = React.useContext(ApplicationContext);
  const {
    planningPreload,
    setParametreContextLoadings,
    setErrors,
  } = React.useContext(DataLoaderContext);

  // Récupération des données préchargées s'il y en a
  useEffect(() => {
    if (!_.isNil(planningPreload)) {
      setTypeCouleur(planningPreload.typeCouleur);
      setCouleurParticulier(planningPreload.couleurParticulier);
    }
  }, [planningPreload]);

  // Récupération des types de réservants
  useEffect(() => {
    Axios.get(getParametres(baseUrl, identifiantEntite))
      .then(({ data }) => {
        const {
          couleurCellules,
          couleurParticulier: particulier,
        } = _.head(data ?? []);

        setTypeCouleur(couleurCellules);
        setCouleurParticulier(particulier);
      }).catch(() => {
        setErrors((prev) => ({ ...prev, typeCouleur: 'Le chargement des paramètres de couleur a échoué' }));
      }).finally(() => {
        setParametreContextLoadings((prev) => ({ ...prev, loadingParametres: false }));
      });
  }, [baseUrl, identifiantEntite]);

  const contextValue = React.useMemo(
    () => ({
      typeCouleur,
      couleurParticulier,
    }),
    [typeCouleur, couleurParticulier],
  );

  return (
    <ParametreContext.Provider value={contextValue}>
      {children}
    </ParametreContext.Provider>
  );
};

ParametreContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ParametreContextProvider;
