import {
  View,
} from '@jvs-group/jvs-mairistem-planning';

/**
  * Retourne le format dayJs prévu pour .add ou .substract en fonction de la vue reçu
  *
  * @param v : View représente la vue à partir de laquel on veut décaler le temps
  */
const getDayJsTimeMove = (v) => {
  switch (v) {
    case View.DAY:
      return [1, 'day'];
    case View.WEEK:
      return [1, 'week'];
    case View.MONTH:
      return [1, 'month'];
    default:
      return [1, 'week'];
  }
};

export default getDayJsTimeMove;
