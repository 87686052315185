import _ from 'lodash';
import React from 'react';

import {
  CalendarView,
  Density,
  Interval,
  Planning as PlanningJvs,
  ResourceContainer,
  ResourceGroup,
  View,
} from '@jvs-group/jvs-mairistem-planning';
import {
  Header,
} from '@jvs-group/jvs-mairistem-composants';

import PlanningHeader from './PlanningHeader';
import { Creneau } from './Creneau';

import { PlanningContext } from '../context';
import { Mode, Planning as PlanningConsts } from '../const';

const Planning = () => {
  const {
    view,
    viewHandlers: { changeToView },
    mode,
    currentDate,
    dateHandlers: {
      goBackward,
      goForward,
      goTo,
    },
    reservationsOfView,
    reservationHandlers: {
      create: createReservation,
    },
    selectedSalles,
  } = React.useContext(PlanningContext);

  return (
    <>
      <PlanningHeader
        onViewChange={changeToView}
        onDateChange={goTo}
        onNextDateClick={goForward}
        onPreviousDateClick={goBackward}
        view={view}
        date={currentDate}
      />
      {mode === Mode.HORIZONTAL && (
        <div // Gère la taille fixe (avec scroll interne) du planning
          style={{
            display: 'flex', // pour que le planning s'étire au max en hauteur
            paddingRight: PlanningConsts.SCROLLBAR_WIDTH,
            ...!_.isEmpty(selectedSalles) && { flex: 1 },
          }}
        >
          <PlanningJvs
            width={12}
            currentDate={currentDate}
            events={reservationsOfView}
            headerDensity={Density.LIGHT}
            view={view}
            onHeaderClick={(mouseEvent, day) => goTo(day, true)}
            interval={
              view === View.DAY
                ? Interval.HALF_HOUR
                : view === View.WEEK
                  ? Interval.HALF_DAY
                  : Interval.DAY
            }
            innerscroll
            getRowHeight={() => PlanningConsts.ROW_HEIGHT}
            hover="cell"
            {...view === View.DAY && { weekendColor: 'black' }}
          >
            <CalendarView onClick={createReservation}>
              {(event) => (
                <Creneau
                  key={event.identifiant}
                  reservations={event.reservations}
                  partiel={event.partiel}
                  dateDebut={event.dateDebut}
                />
              )}
            </CalendarView>
            <ResourceContainer
              collapsible
              resources={selectedSalles}
              headers={[
                'Salles',
              ]}
              getRowHeight={() => PlanningConsts.ROW_HEIGHT}
            >
              <ResourceGroup color="blue" inverted property="libelleTypesSalle" collapsible />
              {(data) => data.nomSalle}
            </ResourceContainer>
          </PlanningJvs>
        </div>
      )}
      {_.isEmpty(selectedSalles) && (
        <Header color="blue" as="h3" textAlign="center">
          Veuillez sélectionner au moins une salle
        </Header>
      )}
    </>
  );
};

export default Planning;
