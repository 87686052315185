import {
  COULEUR_BLEU_CLAIR,
  COULEUR_FOND_VIOLET,
  COULEUR_VERT,
} from '../const/colors';
import ReservationColorType from '../const/reservationColorType';
import TypeReservant from '../const/typeReservant';

/**
 * On renvoie la couleur de la pastille du créneau selon le paramétrage en place
 *
 * @param {int} colorType
 * @param {string} colorParticulier
 * @param {string} colorStatut
 * @param {string} colorTypeReservation
 * @param {int} typeReservant
 *
 * @returns {string}
 */
const getReservationColor = (
  colorType,
  colorParticulier,
  colorStatut,
  colorTypeReservation,
  typeReservant,
) => {
  switch (colorType) {
    case ReservationColorType.STATUT:
      return colorStatut;

    case ReservationColorType.RESERVANT:
      switch (typeReservant) {
        case TypeReservant.ADMINISTRATION:
          return COULEUR_BLEU_CLAIR;
        case TypeReservant.ASSOCIATION:
          return COULEUR_FOND_VIOLET;
        case TypeReservant.ENTREPRISE:
          return COULEUR_VERT;
        case TypeReservant.PARTICULIER:
          return colorParticulier;
        default:
          return '';
      }

    case ReservationColorType.ACTIVITE:
      return colorTypeReservation;

    default:
      return '';
  }
};

export default getReservationColor;
