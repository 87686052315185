import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
  Grid,
  Header,
} from '@jvs-group/jvs-mairistem-composants';

import CalendrierSvg from '../../assets/calendrier.svg';

const DisponibiliteResultsCriteres = (props) => {
  const {
    activite,
    capacite,
    debut,
    fin,
    placesAssises,
    placesDebouts,
    typeReservant,
    typeSalle,
  } = props;

  const criteres = [
    (
      <Grid.Column width={6}>
        <span>Type de réservant : </span>
        <Header as="h5" style={{ display: 'inline' }}>{!_.isNil(typeReservant) ? typeReservant.libelle : 'Tout type de réservant'}</Header>
      </Grid.Column>
    ), (
      <Grid.Column width={10}>
        <span>Activité : </span>
        <Header as="h5" style={{ display: 'inline' }}>{!_.isNil(activite) ? activite.libelle : 'Toutes les activités'}</Header>
      </Grid.Column>
    ), (
      <Grid.Column width={6}>
        <span>Type de salle : </span>
        <Header as="h5" style={{ display: 'inline' }}>{!_.isNil(typeSalle) ? typeSalle.libelle : 'Toutes les salles'}</Header>
      </Grid.Column>
    ),
  ];

  if (!_.isNil(capacite) && capacite > 0) {
    criteres.push((
      <Grid.Column width={3}>
        <span>Capacité : </span>
        <Header as="h5" style={{ display: 'inline' }}>{capacite}</Header>
      </Grid.Column>
    ));
  }

  if (!_.isNil(placesAssises) && capacite > 0) {
    criteres.push((
      <Grid.Column width={3}>
        <span>
          {placesAssises > 1 ? 'Places assises : ' : 'Place assise : '}
        </span>
        <Header as="h5" style={{ display: 'inline' }}>{placesAssises}</Header>
      </Grid.Column>
    ));
  }

  if (!_.isNil(placesDebouts) && capacite > 0) {
    criteres.push((
      <Grid.Column width={4}>
        <span>
          {placesDebouts > 1 ? 'Places debouts : ' : 'Place debout : '}
        </span>
        <Header as="h5" style={{ display: 'inline' }}>{placesDebouts}</Header>
      </Grid.Column>
    ));
  }

  const nombreCriteres = _.size(criteres);

  return (
    <Grid className="resume compact">
      <Grid.Row style={{ alignItems: 'flex-end', paddingBottom: '0.25rem' }}>
        <Grid.Column style={{ width: 'auto' }}>
          <span>
            <img src={CalendrierSvg} alt="Capacité" style={{ height: 17, paddingRight: 5 }} />
            Période de réservation :
          </span>
        </Grid.Column>
        <Grid.Column style={{ width: 'auto' }}>
          <span>du </span>
          <Header as="h5" style={{ display: 'inline' }}>{debut ? debut.format('DD MMMM YYYY - HH:mm') : '-'}</Header>
        </Grid.Column>
        <Grid.Column style={{ width: 'auto' }}>
          <span>au </span>
          <Header as="h5" style={{ display: 'inline' }}>{fin ? fin.format('DD MMMM YYYY - HH:mm') : '-'}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row
        style={{
          paddingTop: '0.25rem',
          paddingBottom: '0.25rem',
        }}
      >
        {criteres[0]}
        {criteres[1]}
      </Grid.Row>
      <Grid.Row
        style={{
          paddingTop: '0.25rem',
          paddingBottom: '0.25rem',
        }}
      >
        {criteres[2]}
        {nombreCriteres > 3 && criteres[3]}
        {nombreCriteres > 4 && criteres[4]}
        {nombreCriteres > 5 && criteres[5]}
      </Grid.Row>
    </Grid>
  );
};

DisponibiliteResultsCriteres.propTypes = {
  activite: PropTypes.number,
  capacite: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // dayjs date
  // eslint-disable-next-line react/forbid-prop-types
  debut: PropTypes.object,
  // dayjs date
  // eslint-disable-next-line react/forbid-prop-types
  fin: PropTypes.object,
  placesAssises: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placesDebouts: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  typeReservant: PropTypes.number,
  typeSalle: PropTypes.number,
};

DisponibiliteResultsCriteres.defaultProps = {
  activite: null,
  capacite: null,
  debut: null,
  fin: null,
  placesAssises: null,
  placesDebouts: null,
  typeReservant: null,
  typeSalle: null,
};

export default DisponibiliteResultsCriteres;
