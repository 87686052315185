import _ from 'lodash';
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import Axios from 'axios';

import { ApplicationContext, DataLoaderContext, ReservantContext } from '../../context';
import {
  getReservants,
  getTypesReservant,
} from '../../routes';

const ReservantContextProvider = ({ children }) => {
  const [typesReservant, setTypesReservant] = React.useState([]);
  const [reservants, setReservants] = React.useState([]);

  const {
    identifiantEntite,
    baseUrl,
  } = React.useContext(ApplicationContext);
  const {
    setReservantContextLoadings,
    planningPreload,
    setErrors,
  } = React.useContext(DataLoaderContext);

  // Récupération des données préchargées s'il y en a
  useEffect(() => {
    if (!_.isNil(planningPreload)) {
      setTypesReservant(planningPreload.typesReservant);
      setReservantContextLoadings((prev) => ({
        ...prev,
        loadingTypesReservant: false,
        // Même si on récupère rien du sessionStorage
        // On en aura pas besoin dans l'immédiat de toute façon
        loadingReservants: false,
      }));
    }
  }, [planningPreload]);

  // Récupération des types de réservants
  useEffect(() => {
    Axios.get(getTypesReservant(baseUrl))
      .then(({ data: { data } }) => {
        setTypesReservant(data);
      }).catch(() => {
        setErrors((prev) => ({ ...prev, typesReservant: 'Le chargement des types de réservant a échoué' }));
      }).finally(() => {
        setReservantContextLoadings((prev) => ({ ...prev, loadingTypesReservant: false }));
      });
  }, [baseUrl]);

  // Récupération des réservants
  useEffect(() => {
    Axios.get(getReservants(baseUrl, identifiantEntite))
      .then(({ data }) => {
        setReservants(data);
      }).catch(() => {
        setErrors((prev) => ({ ...prev, reservants: 'Le chargement des réservants a échoué' }));
      }).finally(() => {
        setReservantContextLoadings((prev) => ({ ...prev, loadingReservants: false }));
      });
  }, [baseUrl, identifiantEntite]);

  const contextValue = React.useMemo(
    () => ({
      typesReservant,
      reservants,
    }),
    [typesReservant, reservants],
  );

  return (
    <ReservantContext.Provider value={contextValue}>
      {children}
    </ReservantContext.Provider>
  );
};

ReservantContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReservantContextProvider;
