/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import _ from 'lodash';
import React from 'react';

import { Header, Icon, Popup } from '@jvs-group/jvs-mairistem-composants';
import { View } from '@jvs-group/jvs-mairistem-planning';

import PlanningCalendar from './PlanningCalendar';
import Button from './Button';
import { PlanningContext } from '../context';

const DateHandler = () => {
  const [calendarOpen, setCalendarOpen] = React.useState(false);

  const {
    view,
    currentDate,
    dateHandlers: {
      goBackward,
      goForward,
      goTo,
    },
  } = React.useContext(PlanningContext);

  const handleDateClick = (date) => {
    setCalendarOpen(false);
    goTo(date, true);
  };

  const dateLabel = React.useMemo(() => {
    let res = '';

    // eslint-disable-next-line default-case
    switch (view) {
      case View.DAY:
        res = currentDate.format('DD MMMM YYYY');
        break;
      case View.WEEK: {
        // On récupère le numéro du jour, le mois et le nom du mois de la date de départ
        const fromDate = currentDate.startOf('week');
        const fromDateDay = fromDate.format('DD');
        const fromDateMonth = fromDate.format('MMMM');

        const toDate = currentDate.endOf('week');
        const toDateDay = toDate.format('DD');
        const toDateMonth = toDate.format('MMMM');

        // Si on est sur le même mois : "Du 04 au 10 janvier"
        if (fromDateMonth === toDateMonth) {
          res = `Du ${fromDateDay} au ${toDateDay} ${toDateMonth}`;
        } else {
          // Si on est sur un mois différent : "Du 29 mars au 04 avril"
          res = `Du ${fromDateDay} ${fromDateMonth} au ${toDateDay} ${toDateMonth}`;
        }
        break;
      }
      case View.MONTH:
        res = _.upperFirst(currentDate.format('MMMM YYYY'));
        break;
    }

    return res;
  }, [currentDate, view]);

  return (
    <Header
      as="h2"
      color="blue"
      textAlign="center"
    >
      <Header.Content style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
        <Button
          inverted
          circular
          color="blue"
          icon="angle left"
          onClick={goBackward}
        />
        <Popup
          position="bottom center"
          style={{
            padding: 0,
            background: 'transparent',
            border: 'none',
            boxShadow: 'none',
          }}
          open={calendarOpen}
          closeOnDocumentClick
          closeOnPortalMouseLeave
          mouseLeaveDelay={500}
          onClose={() => setCalendarOpen(false)}
          trigger={(
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => setCalendarOpen(true)}
            >
              <Icon name="calendar alternate" />
              {dateLabel}
            </div>
            )}
          content={(
            <PlanningCalendar
              currentDate={currentDate}
              onDateClick={handleDateClick}
            />
          )}
        />
        <Button
          style={{ marginLeft: 20 }}
          inverted
          circular
          color="blue"
          icon="angle right"
          onClick={goForward}
        />
      </Header.Content>
    </Header>
  );
};

export default DateHandler;
