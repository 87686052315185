import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
  Grid,
  Icon,
  Header,
} from '@jvs-group/jvs-mairistem-composants';

import { COULEUR_BLEU_JVS } from '../../const/colors';

const ListViewPictos = (props) => {
  const {
    statut,
    type,
    maintenance,
  } = props;

  return (
    <>
      <Grid.Column width={maintenance ? 6 : 8} textAlign="center">
        <div>
          <Icon name="gavel" style={{ color: '#90c647', fontSize: '1.75rem' }} />
        </div>
        <Header
          as="h5"
          style={{
            paddingTop: 5,
            margin: 0,
            color: COULEUR_BLEU_JVS,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          title={statut}
        >
          {!_.isEmpty(statut) ? statut : '-'}
        </Header>
      </Grid.Column>
      <Grid.Column width={maintenance ? 5 : 8} textAlign="center">
        <div>
          <Icon name="puzzle" style={{ color: '#90c647', fontSize: '1.75rem', transform: 'rotate(180deg)' }} />
        </div>
        <Header
          as="h5"
          style={{
            paddingTop: 5,
            margin: 0,
            color: COULEUR_BLEU_JVS,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {_.isEmpty(type) ? '-' : type}
        </Header>
      </Grid.Column>
      {maintenance && (
        <Grid.Column width={5} textAlign="center">
          <div>
            <Icon name="warning sign" style={{ color: '#90c647', fontSize: '1.75rem' }} />
          </div>
          <Header
            as="h5"
            style={{
              paddingTop: 5,
              margin: 0,
              color: COULEUR_BLEU_JVS,
            }}
          >
            En maintenance
          </Header>
        </Grid.Column>
      )}
    </>
  );
};

ListViewPictos.propTypes = {
  statut: PropTypes.string,
  type: PropTypes.string,
  maintenance: PropTypes.bool,
};

ListViewPictos.defaultProps = {
  statut: null,
  type: null,
  maintenance: false,
};

export default ListViewPictos;
