import React from 'react';

import {
  Grid,
} from '@jvs-group/jvs-mairistem-composants';

import PlanningLegend from './PlanningLegend';
import Button from './Button';
import SalleSelect from './SalleSelect';
import DocumentsModal from './Documents/DocumentsModal';
import ViewHandler from './ViewHandler';
import DateHandler from './DateHandler';
import DisponibiliteModal from './Disponiblite/DisponibiliteModal';
import ModeHandler from './ModeHandler';
import ReserverModal from './Reserver/ReserverModal';

const PlanningHeader = () => {
  const [openModalReserver, setOpenModalReserver] = React.useState(false);
  const [openModalDispo, setOpenModalDispo] = React.useState(false);
  const [openModalDocuments, setOpenModalDocuments] = React.useState(false);

  return (
    <Grid style={{ margin: 0 }} doubling stackable>
      <Grid.Row textAlign="center">
        <Grid.Column>
          <ViewHandler />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column style={{ display: 'flex', flexDirection: 'row' }} width={5}>
          <Button
            color="blue"
            inverted
            size="large"
            onClick={() => setOpenModalReserver(true)}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            icon="add to calendar"
            content="Réserver"
          />
          {openModalReserver && (
            <ReserverModal
              onClose={() => setOpenModalReserver(false)}
            />
          )}
          <Button
            color="blue"
            inverted
            size="large"
            onClick={() => setOpenModalDispo(true)}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            icon="search"
            content="Disponibilités"
          />
          {openModalDispo && (
            <DisponibiliteModal
              onClose={() => setOpenModalDispo(false)}
            />
          )}
          <Button
            color="blue"
            inverted
            size="large"
            onClick={() => setOpenModalDocuments(true)}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            icon="file"
            content="Documents"
          />
          {openModalDocuments && (
            <DocumentsModal
              onClose={() => setOpenModalDocuments(false)}
            />
          )}
        </Grid.Column>
        <Grid.Column
          width={6}
          verticalAlign="middle"
        >
          <DateHandler />
        </Grid.Column>
        <Grid.Column
          width={5}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <SalleSelect />
          <ModeHandler />
          <PlanningLegend />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PlanningHeader;
