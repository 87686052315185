import React from 'react';

import { Button as ButtonSemantic } from '@jvs-group/jvs-mairistem-composants';

const Button = (props) => {
  const {
    ...rest
  } = props;

  return (
    <ButtonSemantic
      type="button" // pour éviter le refresh webdev
      {...rest}
    />
  );
};

export default Button;
