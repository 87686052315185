import React from 'react';

const ReservationContext = React.createContext({
  dayReservations: [],
  weekReservations: [],
  monthReservations: [],
  getReservationsOnGivenPeriod: null,

  statuts: [],

  typesReservation: [],
});

export default ReservationContext;
