import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
  Dropdown,
  Icon,
  Label,
} from '@jvs-group/jvs-mairistem-composants';

import CheckableDropdownPopup from './CheckableDropdownPopup';
import CheckableDropdownItem from './CheckableDropdownItem';

import './CheckableDropdown.css';

const CheckableDropdown = (props) => {
  const {
    onOptionSelect,
    options,
    placeholder,
    triggerStyle,
  } = props;

  const placeholderText = placeholder || 'Sélectionner un élément';

  // Liste des items sélectionnés. Ne contient pas les items de type group.
  const selectedItems = React.useMemo(() => {
    const groups = _.filter(options, ({ group: true }));

    let selectedInGroups = [];

    _.map(groups, ({ value, items }) => {
      selectedInGroups = [
        ...selectedInGroups,
        ..._.map(
          _.filter(items, ({ selected }) => selected),
          (i) => ({ ...i, group: value }),
        ),
      ];
    });

    const singleItems = _.filter(options, ({ group, selected }) => !group && selected);

    return [...selectedInGroups, ...singleItems];
  }, [options]);

  // Gestion des options à la sélection
  const handleOptionSelect = React.useCallback((e, data) => {
    if (onOptionSelect) {
      const { selected, group: dataGroup, id } = data;

      const nextSelectedState = !selected;

      // Tableau d'options du résultat après sélection
      const nextOptions = _.map(options, (opt) => {
        const {
          group,
          value,
          items,
        } = opt;

        // Si c'est un groupe, on va voir si l'item qu'on vient de toucher fait parti de ses items
        // en plus de lui-même
        if (group) {
          // Si c'est lui même , il faut aussi mettre à jour tous ses items pour être synchro
          // Le _.isNil(dataGroup) sert pour différencier un item et un group qui aurait le même id
          // Si c'est vide c'est qu'on vient de cliquer sur un groupe
          if (value === id && _.isNil(dataGroup)) {
            return {
              ...opt,
              selected: nextSelectedState,
              items: _.map(items, (i) => ({ ...i, selected: nextSelectedState })),
            };
          }

          // Si c'est pas lui même, que c'est un item, il faut vérifier que :
          // Il doit faire parti du groupe
          if (dataGroup === value) {
            // Si on coche l'item
            if (nextSelectedState) {
              // Si tous les autres items du type sont déjà cochés ; que ça coche le groupe
              if (!_.some(
                items,
                ({ selected: itemSelected, value: itemValue }) => itemValue !== id && !itemSelected,
              )) {
                return {
                  ...opt,
                  selected: nextSelectedState,
                  items: _.map(items, (i) => (i.value === id
                    ? { ...i, selected: nextSelectedState }
                    : i)),
                };
              }

              // Sinon si au moins un aute n'est pas coché, on MAJ seulement l'item concerné
              return {
                ...opt,
                items: _.map(items, (i) => (i.value === id
                  ? { ...i, selected: nextSelectedState }
                  : i)),
              };
            }

            // Si on décoche l'item ; que ça décoche le groupe aussi, forcément
            return {
              ...opt,
              selected: nextSelectedState,
              items: _.map(items, (i) => (i.value === id
                ? { ...i, selected: nextSelectedState }
                : i)),
            };
          }
        }

        // Si c'est pas un groupe
        // C'est l'option qui a changé et c'est tout
        if (value === id) {
          return {
            ...opt,
            selected: nextSelectedState,
          };
        }

        return opt;
      });

      onOptionSelect(
        nextOptions,
        {
          id: data?.id,
          group: data?.group,
          selected: nextSelectedState,
        },
      );
    }
  }, [onOptionSelect, options]);

  // Déselectionne tous les items
  const handleClearClick = React.useCallback(() => {
    if (onOptionSelect) {
      // Tableau d'options du résultat après sélection
      const nextOptions = _.map(options, (opt) => {
        const {
          group,
          items,
        } = opt;

        // Si c'est un groupe, il faut tout décocher, lui et ses items
        if (group) {
          return {
            ...opt,
            selected: false,
            items: _.map(items, (i) => ({ ...i, selected: false })),
          };
        }

        // Si c'est pas un groupe
        return {
          ...opt,
          selected: false,
        };
      });

      onOptionSelect(nextOptions, null);
    }
  }, [onOptionSelect, options]);

  const selectedLabels = React.useMemo(() => (
    <>
      {_.isEmpty(selectedItems) && (
        <div className="divider default text">{placeholderText}</div>
      )}
      {_.map(selectedItems, (item) => <Label key={item.key}>{item.text}</Label>)}
      {_.isEmpty(selectedItems)
        ? (
          <Icon className="dropdown" />
        ) : (
          <Icon
            className="dropdown"
            name="cancel"
            link
            onClick={handleClearClick}
          />
        )}
    </>
  ), [
    handleClearClick,
    placeholderText,
    selectedItems,
  ]);

  const content = React.useMemo(() => (
    <>
      {_.map(options, (option) => {
        if (option.group) {
          return (
            <React.Fragment key={option.key}>
              {/* On rend le titre du groupe */}
              <CheckableDropdownItem
                header
                key={option.key}
                value={option.value}
                text={option.text}
                selected={option.selected}
                {...!_.isNil(onOptionSelect) && { onClick: handleOptionSelect }}
                {..._.omit(option, ['group', 'items'])}
              />
              {/* On rend les items du groupe */}
              {_.map(option.items, (item) => (
                <CheckableDropdownItem
                  group={option.value}
                  {...item}
                  {...!_.isNil(onOptionSelect) && { onClick: handleOptionSelect }}
                />
              ))}
              {/* Si c'est pas le dernier group, on cale un divider */}
              {option.value !== _.last(
                _.filter(
                  options,
                  { group: true },
                ),
              )?.value && <Dropdown.Divider />}
            </React.Fragment>
          );
        }

        // Item tout seul
        return (
          <CheckableDropdownItem
            {...!_.isNil(onOptionSelect) && { onClick: handleOptionSelect }}
            {...option}
          />
        );
      })}
    </>
  ), [
    handleOptionSelect,
    onOptionSelect,
    options,
  ]);

  return (
    <CheckableDropdownPopup
      trigger={selectedLabels}
      triggerStyle={triggerStyle}
      content={content}
      position="bottom center"
      basic
      on="focus"
    />
  );
};

const itemType = PropTypes.shape({
  key: PropTypes.string,
  value: PropTypes.number,
  text: PropTypes.string,
  selected: PropTypes.bool,
});

CheckableDropdown.propTypes = {
  onOptionSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.number,
      text: PropTypes.string,
      group: PropTypes.bool,
      selected: PropTypes.bool,
      items: itemType,
    }),
    itemType,
  ])),
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  triggerStyle: PropTypes.object,
};

CheckableDropdown.defaultProps = {
  onOptionSelect: null,
  options: [],
  placeholder: 'Sélectionner un item',
  triggerStyle: null,
};

export default CheckableDropdown;
