import _ from 'lodash';
import React from 'react';

import Axios from 'axios';
import PropTypes from 'prop-types';

import {
  Form,
  Icon,
  Message,
  Modal,
  Step,
} from '@jvs-group/jvs-mairistem-composants';
import { dayjs } from '@jvs-group/jvs-mairistem-planning';

import Button from '../Button';
import DisponibiliteCriteres from './DisponibiliteCriteres';
import DisponibiliteResults from './DisponibiliteResults';

import {
  ApplicationContext,
  ReservantContext,
  ReservationContext,
  SalleContext,
} from '../../context';
import { getSallesDisponibles } from '../../routes';

const DisponibiliteModal = ({ onClose }) => {
  const [step, setStep] = React.useState(1);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [criteres, setCriteres] = React.useState({
    debut: null,
    fin: null,
    typeReservant: null,
    activite: 0,
    typeSalle: 0,
    capacite: '',
    placesAssises: '',
    placesDebouts: '',
  });
  const [sallesDisponibles, setSallesDisponibles] = React.useState(null);

  /** Récupération des différentes valeurs dans les contexts */
  const {
    identifiantEntite,
    baseUrl,
  } = React.useContext(ApplicationContext);
  const { salles, typesSalle } = React.useContext(SalleContext);
  const { typesReservant } = React.useContext(ReservantContext);
  const { typesReservation } = React.useContext(ReservationContext);

  /** Destructuration */
  const {
    debut,
    fin,
    typeReservant,
    activite,
    typeSalle,
    capacite,
    placesAssises,
    placesDebouts,
  } = criteres;

  const handleSalleSelect = React.useCallback((idSalle) => {
    const salle = _.find(salles, ({ identifiant }) => identifiant === idSalle);
    if (process.env.NODE_ENV !== 'development') {
      vAjouteReservation( // eslint-disable-line
        dayjs(debut).format('YYYY-MM-DD HH:mm'),
        dayjs(fin).format('YYYY-MM-DD HH:mm'),
        salle,
        true,
      );
    // eslint-disable-next-line no-alert
    } else { alert(`Création d'une réservation sur la salle sur la fiche webdev pour la salle : ${salle?.nomSalle}`); }

    if (onClose) {
      onClose();
    }
  }, [salles]);

  const handleSearchClick = React.useCallback(() => {
    if (_.isNil(debut)) {
      setError({ dateDebut: 'La date de début doit être renseignée' });
    } else if (_.isNil(fin)) {
      setError({ dateFin: 'La date de fin doit être renseignée' });
    } else if (_.isNil(typeReservant) || typeReservant === null) {
      setError({ typeReservant: 'Un type de réservant doit être renseigné' });
    } else {
      setError(null);
      setStep(2);

      setLoading(true);

      Axios.get(getSallesDisponibles(
        baseUrl,
        identifiantEntite,
        _.map(salles, ({ identifiant }) => identifiant),
        debut.format('YYYY-MM-DD HH:mm:ss'),
        fin.format('YYYY-MM-DD HH:mm:ss'),
        typeSalle === 0 ? 'all' : typeSalle,
        typeReservant === 0 ? 'all' : typeReservant,
        activite === 0 ? 'all' : activite,
        capacite || 0, // Si ils ne sont pas définis, on les met à 0 pour avoir toutes les salles
        placesAssises || 0,
        placesDebouts || 0,
      )).then(({ data }) => {
        setSallesDisponibles(_.orderBy(
          _.map(data, ({ identifiant }) => _.find(salles, { identifiant })),
          ['identifiantTypesSalle', 'nomSalle'],
        ));
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [
    baseUrl,
    identifiantEntite,
    debut,
    fin,
    salles,
    activite,
    typeSalle,
    capacite,
    placesAssises,
    placesDebouts,
    typeReservant,
  ]);

  const handleBackClick = () => {
    setError(null);
    setStep(1);
  };

  const handleChange = (name, value) => {
    setCriteres({
      ...criteres,
      [name]: value,
    });
    setError(null);
  };

  const handleChanges = (data) => {
    setCriteres({
      ...criteres,
      ...data,
    });
    setError(null);
  };

  return (
    <Modal
      className="modalGDS"
      open
      onClose={onClose}
      closeIcon
    >
      <Modal.Header>
        {/* <RechercheSvg /> */}
        Recherche d&apos;une salle libre
      </Modal.Header>
      <Modal.Content scrolling={false}>
        <Modal.Description>
          <Step.Group className="styled">
            <Step active={step === 1}>
              <Step.Content>
                <Step.Title>Critères de recherche</Step.Title>
              </Step.Content>
              <Icon
                circular
                inverted
                name="check"
                size="small"
              />
            </Step>
            <Step active={step === 2} disabled={step < 2}>
              <Step.Content>
                <Step.Title>Disponibilités</Step.Title>
              </Step.Content>
              <Icon
                circular
                inverted
                name="check"
                size="small"
              />
            </Step>
          </Step.Group>
          <Form autoComplete={false} spellCheck="false">
            {step === 1 && (
            <DisponibiliteCriteres
              error={error}
              onChange={handleChange}
              onChanges={handleChanges}
              {...criteres}
            />
            )}
            {step === 2 && (
            <DisponibiliteResults
              {...criteres}
              typeReservant={_.find(
                typesReservant,
                ({ identifiant }) => identifiant === typeReservant,
              )}
              activite={_.find(typesReservation, ({ identifiant }) => identifiant === activite)}
              typeSalle={_.find(typesSalle, ({ identifiant }) => identifiant === typeSalle)}
              onSalleSelect={handleSalleSelect}
              salles={_.filter(
                sallesDisponibles,
                (salle) => {
                  const {
                    dateMaximum,
                    finDelai,
                  } = salle || {};

                  return (!dateMaximum || fin?.isBefore(dateMaximum))
                        && (!finDelai || debut?.isAfter(finDelai));
                },
              )}
              typesSalle={typesSalle}
              loading={loading}
            />
            )}
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions style={{ display: 'flex', justifyContent: !_.isNil(error) ? 'space-between' : 'flex-end' }}>
        {!_.isNil(error) && (
        <Message
          error
          style={{
            margin: 0, paddingTop: '0.5em', paddingBottom: '0.5em', flex: 1,
          }}
          icon
          size="small"
          compact
        >
          {/* affiche la première erreur qui vient */}
          {error[_.head(_.keys(error))]}
        </Message>
        )}
        {step === 1
          ? (
            <Button
              disabled={error !== null}
              icon="search"
              content="Rechercher"
              color="blue"
              onClick={handleSearchClick}
            />
          ) : (
            <Button
              content="Modifier"
              basic
              icon="angle double left"
              onClick={handleBackClick}
            />
          )}
      </Modal.Actions>
    </Modal>
  );
};

DisponibiliteModal.propTypes = {
  onClose: PropTypes.func,
};

DisponibiliteModal.defaultProps = {
  onClose: null,
};

export default DisponibiliteModal;
