import _ from 'lodash';

import { View } from '@jvs-group/jvs-mairistem-planning';

import addPartielPropertyToCreneaux from './addPartielPropertyToCreneaux';
import resizeCreneaux from './resizeCreneaux';

/**
 * Regroupe toutes les réservations données par créneau, en fonction de la vue.
 *
 * @param {View} view
 * @param {array} reservations
 * @returns
 */
const groupReservationsByCreneau = (view, currentDate, reservations) => {
  if (view === View.DAY) {
    // On regroupe les réservations par salles
    const groupedReservationsBySalle = _.groupBy(reservations ?? [], 'identifiantSalles');
    // On parcourt chaque salle et ses réservations
    const creneauxReserves = _.map(groupedReservationsBySalle, (reservationsList) => {
      // On récupère les réservations triées par date de début
      const orderedReservations = _.orderBy(reservationsList, 'dateDebut', 'asc');
      // On regroupe les réservations par créneau
      const creneaux = [];

      // Pour chaque réservation
      _.forEach(orderedReservations, (reservation) => {
        const {
          identifiantSalles,
          dateDebut,
          dateFin,
        } = reservation;

        // On détermine l'heure de début/fin du créneau (1/2 heures)
        // si dateDebut dans la première demi-heure, créneau débute à heure pile sinon à 30
        const debutCreneauReservation = dateDebut.get('minute') < 30
          ? dateDebut.startOf('hour')
          : dateDebut.startOf('hour').add(30, 'minute');

        // si dateFin est une heure pile, créneau fini à heure pile,
        // sinon si dateFin dans la première demi-heure inclue, créneau fini à 30
        // sinon fini à la "fin de l'heure"
        const finCreneauReservation = dateFin.get('minute') === 0
          ? dateFin
          : dateFin.get('minute') <= 30 && dateFin.get('minute') > 0
            ? dateFin.startOf('hour').add(30, 'minute')
            : dateFin.endOf('hour');

        // Si on a encore aucun créneau encore, on ajoute sans se soucier
        if (_.isEmpty(creneaux)) {
          creneaux.push({
            identifiant: `creneau@${_.size(creneaux)}`,
            identifiantResource: identifiantSalles,
            dateDebut: debutCreneauReservation,
            dateFin: finCreneauReservation,
            // Toutes les réservations de ce créneau seront dans cette propriété
            reservations: [reservation],
          });
        } else {
          // Sinon on regarde le dernier créneau ajouté
          // On récupère le début et la fin du dernier créneau ajouté
          const lastCreneau = _.last(creneaux);
          const {
            dateFin: finCreneau,
          } = lastCreneau;

          // On ajoute un nouveau créneau si
          // pas la même heure
          if (dateDebut.isAfter(finCreneau, 'hour')
            // même heure mais pas même 1/2h (ou début à 30 et fin à 30)
            || (dateDebut.isSame(finCreneau, 'hour') && dateDebut.get('minute') >= 30 && finCreneau.get('minute') <= 30)
            // même heure et même minute
            || (dateDebut.isSame(finCreneau))
          ) {
            creneaux.push({
              identifiant: `creneau@${_.size(creneaux)}`,
              identifiantResource: identifiantSalles,
              dateDebut: debutCreneauReservation,
              dateFin: finCreneauReservation,
              // Toutes les réservations de ce créneau seront dans cette propriété
              reservations: [reservation],
            });
          } else { // Dans le cas contraire on est sur le même créneau
            // On ajoute la reservation aux reservations du créneau
            lastCreneau.reservations.push(reservation);

            // Si heure de fin réservation > heure de fin du créneau
            // → On étire la fin du créneau à la fin du créneau de la réservation en cours
            if (dateFin.isAfter(finCreneau)) {
              lastCreneau.dateFin = finCreneauReservation;
            }
          }
        }
      });

      return creneaux;
    });

    const flattenedCreneaux = _.flatten(creneauxReserves);

    const creneaux = resizeCreneaux(view, currentDate, flattenedCreneaux);

    return addPartielPropertyToCreneaux(creneaux);
  }

  if (view === View.WEEK) {
    // On regroupe les réservations par salles
    const groupedReservationsBySalle = _.groupBy(reservations ?? [], 'identifiantSalles');
    // On parcourt chaque salle et ses réservations
    const creneauxReserves = _.map(groupedReservationsBySalle, (reservationsList) => {
      // On récupère les réservations triées par date de début
      const orderedReservations = _.orderBy(reservationsList, 'dateDebut', 'asc');
      // On regroupe les réservations par créneau
      const creneaux = [];

      // Pour chaque réservation
      _.forEach(orderedReservations, (reservation) => {
        const {
          identifiantSalles,
          dateDebut,
          dateFin,
        } = reservation;

        // On détermine les dates de début/fin du créneau (1/2 journées)
        const debutCreneauReservation = dateDebut.get('hour') < 12
          ? dateDebut.startOf('date')
          : dateDebut.startOf('date').add(12, 'hour');

        const finCreneauReservation = dateFin.get('hour') > 12 || (dateFin.get('hour') === 12 && dateFin.get('minute') > 0)
          ? dateFin.endOf('date')
          : dateFin.endOf('date').subtract(12, 'hour');

        // Si on a encore aucun créneau encore, on ajoute sans ce soucier
        if (_.isEmpty(creneaux)) {
          creneaux.push({
            identifiant: `creneau@${_.size(creneaux)}`,
            identifiantResource: identifiantSalles,
            dateDebut: debutCreneauReservation,
            dateFin: finCreneauReservation,
            // Toutes les réservations de ce créneau seront dans cette propriété
            reservations: [reservation],
          });
        } else {
          // Sinon on regarde le dernier créneau ajouté
          // On récupère le début et la fin du dernier créneau ajouté
          const lastCreneau = _.last(creneaux);
          const {
            dateDebut: debutCreneau,
            dateFin: finCreneau,
          } = lastCreneau;

          // Date de début réservation en cours > date de fin du créneau
          // Ou dates identiques mais heure début réservation > 12 et heure de fin du créneau < 12
          // → Nouveau créneau à ajouter
          if (dateDebut.isAfter(finCreneau)
                || (dateDebut.isSame(finCreneau, 'day') && dateDebut.get('hour') > 12 && debutCreneau.get('hour') < 12)) {
            creneaux.push({
              identifiant: `creneau@${_.size(creneaux)}`,
              identifiantResource: identifiantSalles,
              dateDebut: debutCreneauReservation,
              dateFin: finCreneauReservation,
              reservations: [reservation],
            });
          } else if (dateDebut.isBefore(finCreneau)) {
            // Date de début réservation en cours < date de fin du créneau
            // -> Ajout dans les réservations du créneau
            lastCreneau.reservations.push(reservation);

            // ET si date de fin réservation > date de fin du créneau
            // -> on étire la fin du créneau à la fin du créneau de la réservation en cours
            if (dateFin.isAfter(finCreneau, 'minute')) {
              lastCreneau.dateFin = finCreneauReservation;
            }
          }
        }
      });

      return creneaux;
    });

    const flattenedCreneaux = _.flatten(creneauxReserves);

    const creneaux = resizeCreneaux(view, currentDate, flattenedCreneaux);

    return addPartielPropertyToCreneaux(creneaux);
  }

  if (view === View.MONTH) {
    // On regroupe les réservations par salles
    const groupedReservationsBySalle = _.groupBy(reservations ?? [], 'identifiantSalles');
    // On parcourt chaque salle et ses réservations
    const creneauxReserves = _.map(groupedReservationsBySalle, (reservationsList) => {
      // On récupère les réservations triées par date de début
      const orderedReservations = _.orderBy(reservationsList, 'dateDebut', 'asc');
      // On regroupe les réservations par créneau
      const creneaux = [];

      // Pour chaque réservation
      _.forEach(orderedReservations, (reservation) => {
        const {
          identifiantSalles,
          dateDebut,
          dateFin,
        } = reservation;

        // On détermine les dates de début/fin du créneau
        const debutCreneauReservation = dateDebut.startOf('date');
        const finCreneauReservation = dateFin.endOf('date');

        // Si on a encore aucun créneau encore, on ajoute sans se soucier
        if (_.isEmpty(creneaux)) {
          creneaux.push({
            identifiant: `creneau@${_.size(creneaux)}`,
            identifiantResource: identifiantSalles,
            dateDebut: debutCreneauReservation,
            dateFin: finCreneauReservation,
            // Toutes les réservations de ce créneau seront dans cette propriété
            reservations: [reservation],
          });
        } else {
          // Sinon on regarde le dernier créneau ajouté
          // On récupère le début et la fin du dernier créneau ajouté
          const lastCreneau = _.last(creneaux);
          const {
            dateFin: finCreneau,
          } = lastCreneau;

          // Date de début réservation en cours > date de fin du créneau
          // → Nouveau créneau à ajouter
          if (dateDebut.isAfter(finCreneau)) {
            creneaux.push({
              identifiant: `creneau@${_.size(creneaux)}`,
              identifiantResource: identifiantSalles,
              dateDebut: debutCreneauReservation,
              dateFin: finCreneauReservation,
              reservations: [reservation],
            });
          } else if (dateDebut.isBefore(finCreneau)) {
            // Date de début réservation en cours < date de fin du créneau
            // -> Ajout dans les réservations du créneau
            lastCreneau.reservations.push(reservation);

            // ET si date de fin réservation > date de fin du créneau
            // -> on étire la fin du créneau à la fin du créneau de la réservation en cours
            if (dateFin.isAfter(finCreneau)) {
              lastCreneau.dateFin = finCreneauReservation;
            }
          }
        }
      });

      return creneaux;
    });

    const flattenedCreneaux = _.flatten(creneauxReserves);

    const creneaux = resizeCreneaux(view, currentDate, flattenedCreneaux);

    return addPartielPropertyToCreneaux(creneaux);
  }

  return null;
};

export default groupReservationsByCreneau;
