import _ from 'lodash';

/**
 * Ajoute la propriété "partiel" sur les créneaux
 * qui défini s'il reste du temps libre ou non sur le créneau
 *
 * @param {array} creneaux
 * @returns
 */
const addPartielPropertyToCreneaux = (creneaux) => _.map(
  creneaux,
  (creneau) => {
    const {
      dateDebut: debutCreneau,
      dateFin: finCreneau,
      reservations: reservationsCreneau,
    } = creneau;

    const tempsLibre = finCreneau.diff(debutCreneau, 'minute');

    let tempsReserve = 0;
    _.forEach(reservationsCreneau, ({ dateDebut, dateFin }) => {
      tempsReserve += dateFin.diff(dateDebut, 'minute');
    });

    return {
      ...creneau,
      partiel: tempsReserve < tempsLibre,
    };
  },
);

export default addPartielPropertyToCreneaux;
