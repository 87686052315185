import React from 'react';

import PropTypes from 'prop-types';

import { Popup } from '@jvs-group/jvs-mairistem-composants';

const CheckableDropdownPopup = (props) => {
  const {
    trigger,
    triggerStyle,
    ...rest
  } = props;

  return (
    <Popup
      {...rest}
      className="checkable-dropdown-popup"
      // Première div obligé pour avoir la bonne taille totale sans padding ni margin
      trigger={(
        <div
          style={triggerStyle}
        >
          <div
            className="ui multiple selection dropdown fluid checkable-dropdown"
          >
            {trigger}
          </div>
        </div>
        )}
    />
  );
};

CheckableDropdownPopup.propTypes = {
  trigger: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  triggerStyle: PropTypes.object,
};

CheckableDropdownPopup.defaultProps = {
  trigger: null,
  triggerStyle: null,
};

export default CheckableDropdownPopup;
