import { View } from '@jvs-group/jvs-mairistem-planning';

const viewConstantToFrench = (view) => {
  switch (view) {
    case View.DAY: return 'Jour';
    case View.WEEK: return 'Semaine';
    case View.MONTH: return 'Mois';
    default: return null;
  }
};

export default viewConstantToFrench;
