import React from 'react';

const SalleContext = React.createContext({
  salles: [],

  typesSalle: [],

  typesReservantSalle: [],
});

export default SalleContext;
