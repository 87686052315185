// Couleurs de la charte graphique
export const COULEUR_FOND_VIOLET = '#5270A9';
export const COULEUR_BLEU = '#40A7E1';
export const COULEUR_BLEU_JVS = '#004A99';
export const COULEUR_BLEU_FONCE = '#32AFCB';
export const COULEUR_BLEU_CLAIR = '#3CD5DF';
export const COULEUR_BLEU_CLAIR_TRANSPARENT = '#3cd5df1a';
export const COULEUR_BLEU_CLAIR_DOCUMENTS = '#D5EEF4';
export const COULEUR_VERT = '#8FC746';
export const COULEUR_VERT_CLAIR = '#42D1A1';

// Couleurs réservations
export const COULEUR_INDISPONIBILITE = 'rgba(128,128,128,0.4)';
export const COULEUR_RESERVATION = 'rgb(22,74,110)';

export const SemanticCOLORS = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
];
