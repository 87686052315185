import React from 'react';

import PropTypes from 'prop-types';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';

import { dayjs } from '@jvs-group/jvs-mairistem-planning';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('fr', fr);
setDefaultLocale('fr');

const PlanningCalendar = (props) => {
  const {
    currentDate,
    onDateClick,
  } = props;

  const handleDateChange = React.useCallback((date) => {
    if (onDateClick) {
      onDateClick(dayjs(date));
    }
  }, []);

  return (
    <ReactDatePicker
      inline
      // Fonction "toDate" pour que la date "dayjs"
      // soit utilisable par le picker (= package "moment")
      selected={currentDate?.toDate()}
      onChange={handleDateChange}
      shouldCloseOnSelect
      disabledKeyboardNavigation
    />
  );
};

PlanningCalendar.propTypes = {
  /** dayjs date */
  // eslint-disable-next-line react/forbid-prop-types
  currentDate: PropTypes.object,
  onDateClick: PropTypes.func,
};

PlanningCalendar.defaultProps = {
  currentDate: null,
  onDateClick: null,
};

export default PlanningCalendar;
