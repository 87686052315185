/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
  DateRangePicker,
  Dropdown,
  Form,
  Grid,
  Header,
  Label,
} from '@jvs-group/jvs-mairistem-composants';

import dayjs from 'dayjs';
import CapaciteSvg from '../../assets/capacitebleu.svg';
import PlacesAssisesSvg from '../../assets/placeassisesbleu.svg';
import PlacesDeboutSvg from '../../assets/placesdebout.svg';
import { ReservantContext, ReservationContext, SalleContext } from '../../context';

const DisponibiliteCriteres = (props) => {
  const {
    activite,
    capacite,
    debut,
    error,
    fin,
    placesAssises,
    placesDebouts,
    typeReservant,
    typeSalle,
    onChange,
    onChanges,
  } = props;

  /** States de recherche dans les dropdown */
  const [searchActivite, setSearchActivite] = React.useState(null);
  const [searchTypeReservant, setSearchTypeReservant] = React.useState(null);
  const [searchTypeSalle, setSearchTypeSalle] = React.useState(null);

  /** Récupération des différentes valeurs dans les contexts */
  const { typesSalle } = React.useContext(SalleContext);
  const { typesReservant } = React.useContext(ReservantContext);
  const { typesReservation } = React.useContext(ReservationContext);

  const handleChange = React.useCallback((e, { name, value }) => {
    const val = value === '' ? null : _.toFinite(value);

    if (onChange) {
      onChange(name, val);
    }
  }, [onChange]);

  const handleDateChange = React.useCallback((dates) => {
    if (_.isNil(dates)) {
      onChanges({ debut: null, fin: null });
    } else {
      onChanges({
        debut: dates[0],
        fin: dates[1],
      });
    }
  }, [onChanges]);

  const handleTypeReservantChange = React.useCallback((value) => {
    if (value !== 0) {
      setSearchTypeReservant(null);
    }

    if (onChange) {
      onChange('typeReservant', value);
    }
  }, [onChange]);

  const handleActiviteChange = React.useCallback((value) => {
    if (value !== 0) {
      setSearchActivite(null);
    }

    if (onChange) {
      onChange('activite', value);
    }
  }, [onChange]);

  const handleTypeSalleChange = React.useCallback((value) => {
    if (value !== 0) {
      setSearchTypeSalle(null);
    }

    if (onChange) {
      onChange('typeSalle', value);
    }
  }, [onChange]);

  // Quand on écrit dans les dropdown
  const handleSearchChange = React.useCallback((event) => {
    const { target: { name, value } } = event;

    if (name === 'typeReservant') {
      setSearchTypeReservant(value);
      handleTypeReservantChange(0);
    }

    if (name === 'activite') {
      setSearchActivite(value);
      handleActiviteChange(0);
    }

    if (name === 'typeSalle') {
      setSearchTypeSalle(value);
      handleTypeSalleChange(0);
    }
  }, [handleTypeSalleChange, handleActiviteChange, handleTypeReservantChange]);

  // Quand on sélectionne une opption
  const handleItemSelect = React.useCallback((dropdown) => (e, { value }) => {
    if (dropdown === 'typeReservant') {
      handleTypeReservantChange(value);
    }

    if (dropdown === 'activite') {
      handleActiviteChange(value);
    }

    if (dropdown === 'typeSalle') {
      handleTypeSalleChange(value);
    }
  }, [handleTypeReservantChange, handleActiviteChange, handleTypeSalleChange]);

  // Renvoi vrai si la valeur recherché "searchValue" est dans le nom de l'option "optionValue"
  // ou s'ils sont vide
  const filterPredicate = React.useCallback(
    (searchValue, optionValue) => searchValue === null
        || searchValue === ''
        || optionValue === ''
        || optionValue.toLowerCase().includes(searchValue.toLowerCase()),
    [],
  );

  // Filtre un tableau "list" en fonction d'une valeur de recherche
  const filter = React.useCallback((searchValue, list) => _.filter(
    list,
    ({ text }) => filterPredicate(searchValue, text),
  ), [filterPredicate]);

  // Formate les types de réservations du context en ajoutant le "toutes activités"
  const activitesList = React.useMemo(() => ([
    {
      key: 'all',
      value: 0,
      text: 'Toutes les activités',
    },
    ..._.sortBy(_.map(typesReservation, ({ identifiant, libelle }) => ({
      key: `activite${identifiant}`,
      value: identifiant,
      text: libelle,
    })), ['text']),
  ]), [typesReservation]);

  // Formate les types de salles du context en ajoutant le "toutes les salles"
  const typesSalleList = React.useMemo(() => ([
    {
      key: 'all',
      value: 0,
      text: 'Toutes les salles',
    },
    ..._.sortBy(_.map(typesSalle, ({ identifiant, libelle }) => ({
      key: `typeSalle${identifiant}`,
      value: identifiant,
      text: libelle,
    })), ['text']),
  ]), [typesSalle]);

  // Formate les types de réservants du context en ajoutant le "tout type"
  const typesReservantList = React.useMemo(() => ([
    {
      key: 'all',
      value: 0,
      text: 'Tout type de réservant',
    },
    ..._.sortBy(_.map(typesReservant, ({ identifiant, libelle }) => ({
      key: `typeReservant${identifiant}`,
      value: identifiant,
      text: libelle,
    })), ['text']),
  ]), [typesReservant]);

  // Listes filtrés
  const filteredActivites = React.useMemo(
    () => filter(searchActivite, activitesList),
    [searchActivite, activitesList, filter],
  );
  const filteredTypesReservant = React.useMemo(
    () => filter(searchTypeReservant, typesReservantList),
    [searchTypeReservant, typesReservantList, filter],
  );
  const filteredTypesSalle = React.useMemo(
    () => filter(searchTypeSalle, typesSalleList),
    [searchTypeSalle, typesSalleList, filter],
  );

  return (
    <Grid style={{ marginBottom: 0 }}>
      <Grid.Row>
        <Grid.Column>
          <Form.Field required>
            <label>Date de disponibilité</label>
            <DateRangePicker
              allowEmpty={[false, false]}
              value={[debut, fin]}
              onChange={handleDateChange}
              clearable
              inputReadOnly
              placeholder={['Début', 'Fin']}
              showTime={{
                showSecond: false,
                minuteStep: 15,
                defaultValue: [
                  dayjs().startOf('day').minute(0),
                  dayjs().startOf('day').minute(0),
                ],
                format: 'HH:mm',
              }}
              disabledDate={(date) => date.isBefore(dayjs().startOf('day'))}
              disabledTime={(date) => {
                if (_.isNil(date)) {
                  return {
                    disabledHours: () => _.range(0, 24),
                    disabledMinutes: () => _.range(0, 60),
                  };
                }

                return null;
              }}
              dateFormat="DD MMMM YYYY à HH:mm "
              {...(error?.dateDebut || error?.dateFin) && { error: true }}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header>Autres critères de recherche</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ paddingTop: 0 }}>
        <Grid.Column width={8}>
          <Form.Field required>
            <label>Type de réservant</label>
            <Dropdown
              fluid
              search
              selection
              placeholder="Renseigner une nature"
              options={filteredTypesReservant}
              value={searchTypeReservant ?? _.isNil(typeReservant) ? '' : typeReservant}
              onSearchChange={handleSearchChange}
              onChange={handleItemSelect('typeReservant')}
              {...error?.typeReservant && { error: true }}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column width={8}>
          <Form.Field>
            <label>Pour quelle activité ?</label>
            <Dropdown
              fluid
              search
              selection
              placeholder="Renseigner une activité"
              options={filteredActivites}
              value={searchActivite ?? activite}
              onSearchChange={handleSearchChange}
              onChange={handleItemSelect('activite')}
            />
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={8} style={{ paddingBottom: 0 }}>
          <Form.Field>
            <label>Type de salle</label>
            <Dropdown
              fluid
              search
              selection
              placeholder="Renseigner un type de salle"
              options={filteredTypesSalle}
              value={searchTypeSalle ?? typeSalle}
              onSearchChange={handleSearchChange}
              onChange={handleItemSelect('typeSalle')}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column width={7} style={{ paddingBottom: 0 }}>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              name="capacite"
              label="Capacité"
              labelPosition="left"
              value={capacite}
              onChange={handleChange}
            >
              <Label
                basic
                style={{
                  borderRadius: '25px 0 0 25px',
                  borderRight: 0,
                }}
              >
                <img src={CapaciteSvg} alt="Capacité" style={{ height: 17 }} />
              </Label>
              <input style={{ borderRadius: '0 25px 25px 0' }} />
            </Form.Input>
            <Form.Input
              fluid
              name="placesAssises"
              label="Places assises"
              labelPosition="left"
              value={placesAssises}
              onChange={handleChange}
            >
              <Label
                basic
                style={{
                  borderRadius: '25px 0 0 25px',
                  borderRight: 0,
                }}
              >
                <img src={PlacesAssisesSvg} alt="Places assises" style={{ height: 17 }} />
              </Label>
              <input style={{ borderRadius: '0 25px 25px 0' }} />
            </Form.Input>
            <Form.Input
              fluid
              name="placesDebouts"
              label="Places debouts"
              labelPosition="left"
              value={placesDebouts}
              onChange={handleChange}
            >
              <Label
                basic
                style={{
                  borderRadius: '25px 0 0 25px',
                  borderRight: 0,
                }}
              >
                <img src={PlacesDeboutSvg} alt="Places debouts" style={{ height: 17 }} />
              </Label>
              <input style={{ borderRadius: '0 25px 25px 0' }} />
            </Form.Input>
          </Form.Group>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

DisponibiliteCriteres.propTypes = {
  activite: PropTypes.number,
  capacite: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // dayjs date
  debut: PropTypes.object,
  error: PropTypes.object,
  // dayjs date
  fin: PropTypes.object,
  placesAssises: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placesDebouts: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  typeReservant: PropTypes.number,
  typeSalle: PropTypes.number,
  onChange: PropTypes.func,
  onChanges: PropTypes.func,
};

DisponibiliteCriteres.defaultProps = {
  activite: null,
  capacite: null,
  debut: null,
  error: null,
  fin: null,
  placesAssises: null,
  placesDebouts: null,
  typeReservant: null,
  typeSalle: null,
  onChange: null,
  onChanges: null,
};

export default DisponibiliteCriteres;
