import _ from 'lodash';
import { View } from '@jvs-group/jvs-mairistem-planning';

const viewFrenchToConstant = (view) => {
  switch (_.toLower(view)) {
    case 'jour': return View.DAY;
    case 'semaine': return View.WEEK;
    case 'mois': return View.MONTH;
    default: return null;
  }
};

export default viewFrenchToConstant;
