import _ from 'lodash';
import React from 'react';
import Axios from 'axios';

import PropTypes from 'prop-types';
import {
  Dimmer,
  Grid,
  Header,
  Loader,
} from '@jvs-group/jvs-mairistem-composants';
import { dayjs } from '@jvs-group/jvs-mairistem-planning';

import SallePhotoList from './SallePhotoList';
import SalleDescriptionPictos from './SalleDescriptionPictos';
import TelechargerSvg from '../../assets/telecharger.svg';
import { ApplicationContext } from '../../context';
import {
  downloadDocumentsBySalles,
  getDocumentsBySalle,
  getSalleImages,
} from '../../routes';

const SalleDescription = (props) => {
  const {
    identifiant,
    download,
    nom,
    description,
    heureDebut,
    heureFin,
    placesTotales,
    placesAssises,
    superficie,
  } = props;

  const [documents, setDocuments] = React.useState(null);
  const [downloadLoading, setDownloadLoading] = React.useState(false);
  const [photos, setPhotos] = React.useState([]);

  const { baseUrl, identifiantEntite } = React.useContext(ApplicationContext);

  const heureDebutFormatted = _.isEmpty(heureDebut)
    ? ''
    : `${heureDebut.substr(0, 2)}h${heureDebut.substr(2, 2)}`;

  const heureFinFormatted = _.isEmpty(heureFin)
    ? ''
    : `${heureFin.substr(0, 2)}h${heureFin.substr(2, 2)}`;

  const handleDescriptifDownload = () => {
    setDownloadLoading(true);

    Axios.get(
      downloadDocumentsBySalles(
        baseUrl,
        identifiantEntite,
        identifiant,
      ),
      { responseType: 'blob' },
    ).then((data) => {
      const zipName = `documents_planning_${dayjs().format('DDMMYYYYHHiiss')}.zip`;
      const zipFile = new File([data], zipName, { type: 'application/zip' });
      const zipURL = URL.createObjectURL(zipFile);
      const downloadLink = document.createElement('a');
      downloadLink.href = zipURL;
      downloadLink.setAttribute('download', zipName);
      downloadLink.click();
      URL.revokeObjectURL(zipURL);
      downloadLink.remove();
    }).finally(() => { setDownloadLoading(false); });
  };

  React.useEffect(() => {
    if (!_.isNil(identifiant) && identifiant > 0) {
      Axios.get(getSalleImages(baseUrl, identifiantEntite, identifiant))
        .then((data) => {
          setPhotos(data?.data);
        });
    }
  }, [baseUrl, identifiantEntite, identifiant]);

  React.useEffect(() => {
    if (download) {
      Axios.get(getDocumentsBySalle(baseUrl, identifiantEntite, identifiant))
        .then((data) => {
          setDocuments(data.data);
        });
    }
  }, [
    baseUrl,
    download,
    identifiant,
    identifiantEntite,
  ]);

  const shouldDisplayDownload = download && !_.isEmpty(
    _.filter(
      documents,
      ({ identifiantEnregistrementOrigine }) => identifiantEnregistrementOrigine === identifiant,
    ),
  );

  const hasNoData = _.isEmpty(description)
        && _.isEmpty(heureDebutFormatted)
        && _.isEmpty(heureFinFormatted)
        && (_.isNil(superficie) || superficie === 0)
        && (_.isNil(placesAssises) || placesAssises === 0)
        && (_.isNil(placesTotales) || placesTotales === 0);

  if (hasNoData && shouldDisplayDownload && _.isEmpty(photos)) {
    return (
      <Grid>
        <Grid.Row style={{ paddingRight: 10 }}>
          <Grid.Column width={12}>
            <Grid>
              {nom && (
              <Grid.Row>
                <Grid.Column>
                  <Header as="h2">
                    {nom}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column textAlign="justified">
                  <Header as="h4">
                    Aucune information n&pos;a été renseigné pour cette salle
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Dimmer.Dimmable
            as={Grid.Column}
            textAlign="center"
            width={4}
            style={{ cursor: 'pointer' }}
            onClick={handleDescriptifDownload}
          >
            <Dimmer inverted active={downloadLoading}>
              <Loader />
            </Dimmer>
            <div>
              <img src={TelechargerSvg} alt="Télécharger" style={{ height: 17 }} />
              <Header
                as="h4"
                style={{
                  margin: 0,
                }}
              >
                Télécharger le descriptif complet
              </Header>
            </div>
          </Dimmer.Dimmable>
        </Grid.Row>
      </Grid>
    );
  }

  return (
    <Grid>
      <Grid.Row
        style={{
          ...!_.isEmpty(photos) && { height: 165 },
          paddingRight: 10,
        }}
      >
        <Grid.Column width={_.isEmpty(photos) ? 16 : 12}>
          <Grid>
            {nom && (
            <Grid.Row>
              <Grid.Column>
                <Header as="h2">
                  {nom}
                </Header>
              </Grid.Column>
            </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column textAlign="justified">
                <Header as="h4">
                  {hasNoData
                    ? "Aucune information n'a été renseigné pour cette salle"
                    : _.isEmpty(description)
                      ? 'Aucun descriptif disponible'
                      : 'Descriptif de la salle'}
                  {description && (
                    <Header.Subheader>
                      {description}
                    </Header.Subheader>
                  )}
                </Header>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        {!_.isEmpty(photos) && (
        <Grid.Column width={4} textAlign="center">
          <SallePhotoList photos={photos} />
        </Grid.Column>
        )}
      </Grid.Row>
      {!hasNoData && (
        <Grid.Row textAlign="center" style={{ paddingRight: 10 }}>
          <Grid.Column
            width={(shouldDisplayDownload || downloadLoading) ? 12 : 16}
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <SalleDescriptionPictos
              superficie={superficie}
              heureDebut={heureDebutFormatted}
              heureFin={heureFinFormatted}
              placesAssises={placesAssises}
              placesTotales={placesTotales}
            />
          </Grid.Column>
          {shouldDisplayDownload
            ? (
              <Dimmer.Dimmable
                as={Grid.Column}
                width={4}
                style={{ cursor: 'pointer' }}
                onClick={handleDescriptifDownload}
              >
                <Dimmer inverted active={downloadLoading}>
                  <Loader />
                </Dimmer>
                <div>
                  <img src={TelechargerSvg} alt="Télécharger" style={{ height: 17 }} />
                  <Header
                    as="h4"
                    style={{
                      margin: 0,
                    }}
                  >
                    Télécharger le descriptif complet
                  </Header>
                </div>
              </Dimmer.Dimmable>
            ) : (<Grid.Column width={4} />)}
        </Grid.Row>
      )}
    </Grid>
  );
};

SalleDescription.propTypes = {
  identifiant: PropTypes.number,
  nom: PropTypes.string,
  heureDebut: PropTypes.string,
  heureFin: PropTypes.string,
  download: PropTypes.bool,
  description: PropTypes.string,
  placesTotales: PropTypes.number,
  placesAssises: PropTypes.number,
  superficie: PropTypes.number,
};

SalleDescription.defaultProps = {
  identifiant: null,
  nom: null,
  heureDebut: null,
  heureFin: null,
  download: true,
  description: null,
  placesTotales: null,
  placesAssises: null,
  superficie: null,
};

export default SalleDescription;
