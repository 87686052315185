import _ from 'lodash';
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import Axios from 'axios';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import { View, dayjs } from '@jvs-group/jvs-mairistem-planning';

import {
  ApplicationContext,
  DataLoaderContext,
  ReservationContext,
  SalleContext,
} from '../../context';
import { getReservations, getStatuts, getTypesReservation } from '../../routes';
import WEB_EN_ATTENTE from '../../const/webEnAttente';

dayjs.extend(dayOfYear);
dayjs.extend(weekOfYear);

// Objet de forme { 1: [], 2: [], 3: [] .... } jusque 366 jours
const createDaysObject = () => _.omit(_.toPlainObject(_.times(367, () => ([]))), [0]);
// Objet de forme { 1: [], 2: [], 3: [] .... } jusque 52 semaines
const createWeeksObject = () => _.omit(_.toPlainObject(_.times(53, () => ([]))), [0]);
// Objet de forme { 1: [], 2: [], 3: [] .... } jusque 12 mois
const createMonthsObject = () => _.omit(_.toPlainObject(_.times(13, () => ([]))), [0]);

const fullDateFormat = 'YYYY-MM-DD';

const ReservationContextProvider = ({ children }) => {
  const [dayReservations, setDayReservations] = React.useState(createDaysObject());
  const [weekReservations, setWeekReservations] = React.useState(createWeeksObject());
  const [monthReservations, setMonthReservations] = React.useState(createMonthsObject());

  const [statuts, setStatuts] = React.useState([]);
  const [typesReservation, setTypesReservation] = React.useState([]);

  const reservationRequestController = React.useRef(new AbortController());

  const {
    identifiantEntite,
    baseUrl,
    affichageDefaut: { date: defaultDate },
  } = React.useContext(ApplicationContext);
  const {
    salles,
  } = React.useContext(SalleContext);
  const {
    setReservationContextLoadings,
    planningPreload,
    setErrors,
  } = React.useContext(DataLoaderContext);

  const today = React.useMemo(() => dayjs(), []);

  /**
   * Permet d'annuler la volée de requête précédente, potentiellement non complétée
   * et dont on a plus besoin.
   */
  const wrapWithRequestController = React.useCallback((cb) => (...args) => {
    reservationRequestController.current.abort();
    reservationRequestController.current = new AbortController();
    cb(...args);
  }, []);

  /**
   * Récupère les réservations pour d'une date donnée, en fonction du type de vue.
   *
   * @param View view   Type de vue (View.DAY, View.WEEK, View.Month)
   * @param dayjs date  Date incluse dans la période du type de vue
   *                    (un jour dans la semaine, ou dans le mois suffit)
   * @param int offset  Combien de marge on prend autour de la date.
   *                    Un offset de 2, pour une view "View.WEEK", récupérera 5 semaines
   *                    (2 avant, 2 après, et celle de la date)
   */
  const getReservationsOnGivenPeriod = React.useCallback((view, date, offset = 0) => {
    const periodStart = date.startOf(view);
    const periodEnd = date.add(1, 'day').endOf(view);

    // Récupération des réservations pour la date donnée, jour, semaine ou mois
    Axios.get(getReservations(
      baseUrl,
      periodStart.format(fullDateFormat),
      periodEnd.format(fullDateFormat),
      identifiantEntite,
    ), { signal: reservationRequestController.current.signal }).then(({ data }) => {
      let setter = null;
      let numero = null;

      // Détermine pour la date, quel numéro de jour / semaine / mois dans l'année
      // Ainsi que le setter pour le bon state
      // eslint-disable-next-line default-case
      switch (view) {
        case View.DAY:
          numero = date.dayOfYear();
          setter = setDayReservations;
          break;
        case View.WEEK:
          numero = date.week();
          setter = setWeekReservations;
          break;
        case View.MONTH:
          numero = date.month() + 1;
          setter = setMonthReservations;
          break;
      }

      // Met à jour la bonne période dans le bon state (ex: semaine n°12 du state weekReservations)
      // avec les données récupérées, formatées pour ajouter la salle où est la réservation
      setter((prevReservations) => ({
        ...prevReservations,
        [numero]: _.map(data, (reservation) => ({
          ...reservation,
          dateDebut: dayjs(reservation.dateDebut),
          dateFin: dayjs(reservation.dateFin),
          identifiantResource: reservation?.identifiantSalles ?? 0,
        })),
      }));

      // Gestion de l'offset, si on veut récupérer des réservations en prévision avant et après
      if (_.isFinite(offset) && offset > 0) {
        _.times(offset, (n) => {
          getReservationsOnGivenPeriod(view, date.add(n + 1, view));
          getReservationsOnGivenPeriod(view, date.subtract(n + 1, view));
        });
      }
    }).catch((err) => {
      // évite les erreurs js dans la console, on sait pourquoi on les cancel de toute façon
      if (err.name === 'CanceledError') return;

      setErrors((prev) => ({ ...prev, reservations: 'Le chargement des réservations a échoué' }));
    }).finally(() => {
      setReservationContextLoadings((prev) => ({ ...prev, loadingReservations: false }));
    });
  }, [
    baseUrl,
    salles,
    identifiantEntite,
    reservationRequestController,
  ]);

  // Récupération des réservations autour d'"aujourd'hui" ou de la date de webdev
  // pour tous les types de vues lors du premier affichage du planning.
  // Pas opti mais ce sera les cas d'utilisations les plus courants, ça évite le chargement
  // lors des premiers changement de vue (semaine -> mois ou semaine -> jour).
  React.useEffect(() => {
    let date = today;

    if (!_.isEmpty(defaultDate)) {
      date = dayjs(defaultDate);
    }
    // 3 semaines avant, 3 semaines après
    getReservationsOnGivenPeriod(View.WEEK, date, 2);
    getReservationsOnGivenPeriod(View.MONTH, date);
    // 1 jours avant, 1 jours après
    getReservationsOnGivenPeriod(View.DAY, date, 1);
  }, [today]);

  // Récupération des statuts
  useEffect(() => {
    Axios.get(getStatuts(baseUrl, identifiantEntite))
      .then(({ data: { data } }) => {
        setStatuts(_.map(data, (statut) => ({
          ...statut,
          webEnAttente: statut.libelle === WEB_EN_ATTENTE,
        })));
      }).catch(() => {
        setErrors((prev) => ({ ...prev, statuts: 'Le chargement des statuts a échoué' }));
      }).finally(() => {
        setReservationContextLoadings((prev) => ({ ...prev, loadingStatuts: false }));
      });
  }, [
    baseUrl,
    identifiantEntite,
  ]);

  // Récupération des types de réservation
  useEffect(() => {
    Axios.get(getTypesReservation(baseUrl, identifiantEntite))
      .then(({ data }) => {
        setTypesReservation(data);
      }).catch(() => {
        setErrors((prev) => ({ ...prev, typesReservation: 'Le chargement des activités a échoué' }));
      }).finally(() => {
        setReservationContextLoadings((prev) => ({ ...prev, loadingTypesReservation: false }));
      });
  }, [
    baseUrl,
    identifiantEntite,
  ]);

  // Récupération des données préchargées s'il y en a
  useEffect(() => {
    if (!_.isNil(planningPreload)) {
      setStatuts(planningPreload.statuts);
      setTypesReservation(planningPreload.typesReservation);
      setReservationContextLoadings((prev) => ({
        ...prev,
        loadingStatuts: false,
        loadingTypesReservation: false,
        // A voir si on met pas le loadingReservations à false aussi
        // Mais vu qu'on vient potentiellement de créer une réservation,
        // vaut mieux s'assurer qu'on recharge tout avant de débloquer le planning
        // loadingReservations: false,
      }));
    }
  }, [planningPreload]);

  const contextValue = React.useMemo(
    () => ({
      dayReservations,
      weekReservations,
      monthReservations,
      getReservationsOnGivenPeriod: wrapWithRequestController(getReservationsOnGivenPeriod),
      statuts,
      typesReservation,
    }),
    [
      dayReservations,
      weekReservations,
      monthReservations,
      getReservationsOnGivenPeriod,
      wrapWithRequestController,
      statuts,
      typesReservation,
    ],
  );

  return (
    <ReservationContext.Provider value={contextValue}>
      {children}
    </ReservationContext.Provider>
  );
};

ReservationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReservationContextProvider;
