export const getDocuments = (
  baseUrl,
  identifiantEntite,
) => `${baseUrl}/api/documentInformations/search?identifiantEntite=${identifiantEntite}&type=DOCSAL&perPage=200`;

export const getDocumentsBySalle = (
  baseUrl,
  identifiantEntite,
  identifiantSalle,
) => `${baseUrl}/api/documentInformations/search?identifiantEntite=${identifiantEntite}&type=DOCSAL&perPage=200&identifiantEnregistrementOrigine=${identifiantSalle}`;

export const downloadDocumentsBySalles = (
  baseUrl,
  identifiantEntite,
  identifiantsSalleList,
) => `${baseUrl}/api/documentInformations/download?idEntite=${identifiantEntite}&idSalleList=${identifiantsSalleList}`;

export const getSalleImages = (
  baseUrl,
  identifiantEntite,
  identifiantSalle,
) => `${baseUrl}/api/salles/${identifiantSalle}/images?identifiantEntite=${identifiantEntite}`;
