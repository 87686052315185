import _ from 'lodash';
import React from 'react';

import {
  DataLoaderContext,
  ReservantContext,
  ReservationContext,
  SalleContext,
  ParametreContext,
} from '../../context';

const SessionStorageUpdater = () => {
  const { fullyLoaded } = React.useContext(DataLoaderContext);
  const { salles, typesReservantSalle, typesSalle } = React.useContext(SalleContext);
  const { typesReservant } = React.useContext(ReservantContext);
  const { statuts, typesReservation } = React.useContext(ReservationContext);
  const { typeCouleur, couleurParticulier } = React.useContext(ParametreContext);

  const updateSessionStorage = React.useCallback((key, value) => {
    const data = sessionStorage.getItem('planningPreload');

    if (!_.isNil(data)) {
      const parsedData = JSON.parse(data);

      parsedData[key] = value;

      sessionStorage.setItem('planningPreload', JSON.stringify(parsedData, null, 2));
    } else {
      sessionStorage.setItem('planningPreload', JSON.stringify({ [key]: value }, null, 2));
    }
  }, []);

  // Synchronise le sessionStorage avec les données des différents context
  React.useEffect(() => {
    if (fullyLoaded) {
      updateSessionStorage('salles', salles);
      updateSessionStorage('typesReservantSalle', typesReservantSalle);
      updateSessionStorage('typesSalle', typesSalle);
      updateSessionStorage('typesReservant', typesReservant);
      updateSessionStorage('statuts', statuts);
      updateSessionStorage('typesReservation', typesReservation);
      updateSessionStorage('typeCouleur', typeCouleur);
      updateSessionStorage('couleurParticulier', couleurParticulier);
    }
  }, [
    fullyLoaded,
    salles,
    typesReservantSalle,
    typesSalle,
    typesReservant,
    statuts,
    typesReservation,
    typeCouleur,
    couleurParticulier,
  ]);

  return null;
};

export default SessionStorageUpdater;
