import _ from 'lodash';

/**
 * On recoupe les créneaux pour qu'ils soient limités à la vue
 * Si on est en mois
 *  -> le début des créneaux ne doit pas être antérieur au début du mois
 *  -> la fin des créneaux ne doit pas être postérieur à la fin du mois
 * Si on est en semaine
 *  -> le début des créneaux ne doit pas être antérieur au début de la semaine
 *  -> la fin des créneaux ne doit pas être postérieur à la fin de la semaine
 * Si on est en jour
 *  -> le début des créneaux ne doit pas être antérieur au début du jour
 *  -> la fin des créneaux ne doit pas être postérieur à la fin du jour
 *
 * @param {View} view
 * @param {array} creneaux
 *
 * @returns {array}
 */
const resizeCreneaux = (view, currentDate, creneaux) => _.map(creneaux, (creneau) => {
  const { dateDebut: debutCreneau, dateFin: finCreneau } = creneau;

  let debutCreneauReel = debutCreneau;
  let finCreneauReel = finCreneau;

  // Si le début du créneau est avant le début de la période
  if (debutCreneau.isBefore(currentDate.startOf(view))) {
    debutCreneauReel = currentDate.startOf(view);
  }

  // Si le fin du créneau est après le fin de la période
  if (finCreneau.isAfter(currentDate.endOf(view))) {
    finCreneauReel = currentDate.endOf(view);
  }

  return {
    ...creneau,
    dateDebut: debutCreneauReel,
    dateFin: finCreneauReel,
  };
});

export default resizeCreneaux;
