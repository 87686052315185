import React from 'react';

import {
  Grid,
  Header,
  Icon,
  Popup,
} from '@jvs-group/jvs-mairistem-composants';

import {
  COULEUR_BLEU_JVS,
  COULEUR_FOND_VIOLET,
  COULEUR_INDISPONIBILITE,
} from '../const/colors';

const PlanningLegend = () => {
  const render = React.useCallback((text, color, striped) => (
    <Grid.Column>
      <div
        style={{
          display: 'flex',
          float: 'left',
          height: '2rem',
          width: '2rem',
          marginRight: '0.5rem',
          background: striped
            ? `-webkit-repeating-linear-gradient(45deg,  
              ${color},
              ${color} 4.5px,
              #FFFFFF 4.5px, 
              #FFFFFF 9px`
            : `${color}`,
        }}
      />
      <div
        style={{
          fontWeight: 'bold',
          color: `${COULEUR_FOND_VIOLET}`,
        }}
      >
        {text}
      </div>
    </Grid.Column>
  ), []);

  const indisponible = React.useMemo(() => render(
    'Indisponible',
    COULEUR_INDISPONIBILITE,
    false,
  ), [render]);

  const reserve = React.useMemo(() => render(
    'Réservé',
    COULEUR_BLEU_JVS,
  ), [render]);

  const partiellementDisponible = React.useMemo(() => render(
    'Créneau partiellement disponible',
    COULEUR_BLEU_JVS,
    true,
  ), [render]);

  return (
    <Popup
      hoverable
      trigger={(
        <Header as="h3" floated="right">
          <Icon name="help circle" color="blue" />
        </Header>
    )}
      content={(
        <Grid style={{ width: 300 }}>
          <Grid.Row>
            {indisponible}
          </Grid.Row>
          <Grid.Row style={{ padding: 0 }}>
            {reserve}
          </Grid.Row>
          <Grid.Row>
            {partiellementDisponible}
          </Grid.Row>
        </Grid>
    )}
      position="left center"
    />
  );
};

export default PlanningLegend;
