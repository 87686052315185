export const getReservations = (
  baseUrl,
  start,
  end,
  identifiantEntite,
) => `${baseUrl}/api/plannings/range/start/${start}/end/${end}?idEntite=${identifiantEntite}`;

export const getTypesReservation = (
  baseUrl,
  identifiantEntite,
) => `${baseUrl}/api/typeReservation?perPage=100&idEntite=${identifiantEntite}`;

export const getStatuts = (
  baseUrl,
  identifiantEntite,
) => `${baseUrl}/api/statuts?idEntite=${identifiantEntite}`;
