import React from 'react';

import Planning from './components/Planning';
import { DataProvider, ApplicationContextProvider } from './components/DataProvider';
import PlanningContextProvider from './components/PlanningContextProvider';

const App = () => (
  <ApplicationContextProvider>
    <DataProvider>
      <PlanningContextProvider>
        <Planning />
      </PlanningContextProvider>
    </DataProvider>
  </ApplicationContextProvider>
);

export default App;
