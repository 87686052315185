import _ from 'lodash';
import React from 'react';

import Axios from 'axios';
import PropTypes from 'prop-types';

import { dayjs } from '@jvs-group/jvs-mairistem-planning';
import {
  Icon,
  Input,
  Modal,
  Form,
  Loader,
  Dimmer,
  Message,
} from '@jvs-group/jvs-mairistem-composants';

import Button from '../Button';
import DocumentTable from './DocumentTable';
import { ApplicationContext, SalleContext } from '../../context';
import { downloadDocumentsBySalles, getDocuments } from '../../routes';

const DocumentsModal = (props) => {
  const {
    container,
    onClose,
  } = props;

  const [error, setError] = React.useState({});
  const [documents, setDocuments] = React.useState([]);
  const [selectedSallesIds, setSelectedSallesIds] = React.useState([]);
  const [loadingDocuments, setLoadingDocuments] = React.useState(true);
  const [loadingDownload, setLoadingDownload] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');

  const {
    baseUrl,
    identifiantEntite,
  } = React.useContext(ApplicationContext);

  const {
    salles,
    typesSalle,
  } = React.useContext(SalleContext);

  // Tableau des salles qui ont des documents uniquement
  const sallesWithDocuments = React.useMemo(() => {
    const onlyWithDocuments = _.filter(
      salles,
      ({ identifiant }) => _.includes(_.map(documents, 'identifiantSalle'), identifiant),
    );

    // On rajoute les documents de chaque salle en plus
    return _.map(onlyWithDocuments, (salle) => ({
      ...salle,
      documents: _.filter(documents, { identifiantSalle: salle.identifiant }),
    }));
  }, [salles, documents]);

  // Salles qui correspondent au filtre uniquement
  const filteredSalles = React.useMemo(() => _.filter(
    sallesWithDocuments,
    ({ nomSalle, libelleTypesSalle }) => {
      // Si on a pas de filtre, on prend tout
      if (_.isEmpty(filterText)) {
        return true;
      }

      // si le filtre est dans le nom la salle ou dans le type, on garde
      if (_.includes(_.toLower(nomSalle), _.toLower(filterText))
        || _.includes(_.toLower(libelleTypesSalle), _.toLower(filterText))) {
        return true;
      }

      // sinon on garde pas
      return false;
    },
  ), [filterText, sallesWithDocuments]);

  // Tableau d'identifiants des salles filtrées
  const filteredSallesIds = React.useMemo(
    () => _.map(filteredSalles, 'identifiant'),
    [filterText, sallesWithDocuments],
  );

  // Gestion du clic sur la coche "toutes les salles"
  const handleAllSelect = React.useCallback((event, data) => {
    const {
      checked,
    } = data;

    if (checked) {
      setSelectedSallesIds(filteredSallesIds);
    } else {
      setSelectedSallesIds((prev) => _.filter(prev, (id) => (!_.includes(filteredSallesIds, id))));
    }
  }, [filteredSallesIds, sallesWithDocuments]);

  // Gestion de la sélection d'une salle
  const handleItemSelect = React.useCallback((event, data) => {
    const {
      checked,
      name: selectedId,
    } = data;

    if (checked) {
      setSelectedSallesIds((prev) => _.uniq([...prev, _.parseInt(selectedId, 10)]));
    } else {
      setSelectedSallesIds((prev) => _.filter(prev, (id) => id !== _.parseInt(selectedId, 10)));
    }
  }, []);

  // Gestion du filtrage via le champ de saisie
  const handleFilterTextChange = React.useCallback(({ target: { value } }) => {
    setFilterText(value ?? '');
  }, []);

  // Gestion du téléchargement
  const handleDownloadClick = React.useCallback(() => {
    setError((prev) => _.omit(prev, 'download'));

    if (!_.isEmpty(selectedSallesIds)) {
      setLoadingDownload(true);

      Axios.get(
        downloadDocumentsBySalles(baseUrl, identifiantEntite, _.join(selectedSallesIds, ',')),
        { responseType: 'blob' },
      ).then((data) => {
        const zipName = `documents_planning_${dayjs().format('DDMMYYYYHHiiss')}.zip`;
        const zipFile = new File([data], zipName, { type: 'application/zip' });
        const zipURL = URL.createObjectURL(zipFile);
        const downloadLink = document.createElement('a');
        downloadLink.href = zipURL;
        downloadLink.setAttribute('download', zipName);
        downloadLink.click();
        URL.revokeObjectURL(zipURL);
        downloadLink.remove();
      }).catch(() => {
        setError({ download: 'Le téléchargement des documents a échoué' });
      }).finally(() => {
        setLoadingDownload(false);
      });
    }
  }, [selectedSallesIds]);

  // Récupération des documents
  React.useEffect(() => {
    Axios.get(getDocuments(baseUrl, identifiantEntite))
      .then(({ data }) => {
        // On reformate pour avoir le minimum utile
        setDocuments(_.map(data.data, (doc) => ({
          identifiant: doc.identifiant,
          nom: doc.nom,
          identifiantSalle: doc.identifiantEnregistrementOrigine,
        })));
      }).finally(() => {
        setLoadingDocuments(false);
      });
  }, [
    baseUrl,
    identifiantEntite,
  ]);

  return (
    <Modal
      mountNode={container}
      open
      onClose={onClose}
      size="tiny"
      closeIcon
      colorHeader="red"
      // fix pour les ressources du planning
      style={{ zIndex: 1001 }}
    >
      <Modal.Header>
        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <Icon name="file alternate" size="large" />
          Recherche de documents
        </div>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Input
            value={filterText}
            onChange={handleFilterTextChange}
            placeholder="Saisir le nom d'une salle ou d'un type de salle"
            icon="search"
          />
          {loadingDocuments
            ? (
              <Dimmer active inverted>
                <Loader size="big" />
              </Dimmer>
            )
            : (
              <DocumentTable
                items={filteredSalles}
                // Pour n'envoyer que les id qui se trouvent dans filteredSalles
                selectedItems={_.filter(
                  selectedSallesIds,
                  (id) => _.includes(_.map(filteredSalles, 'identifiant'), id),
                )}
                typesSalles={typesSalle}
                onItemSelect={handleItemSelect}
                onAllSelect={handleAllSelect}
              />
            )}
        </Form>
      </Modal.Content>
      <Modal.Actions style={{ display: 'flex', justifyContent: error.download ? 'space-between' : 'flex-end' }}>
        {loadingDownload && (
          <Dimmer active inverted>
            <Loader size="big" />
          </Dimmer>
        )}
        {!_.isNil(error.download) && (
          <Message
            error
            style={{
              margin: 0, paddingTop: '0.5em', paddingBottom: '0.5em', flex: 1,
            }}
            icon
            size="small"
            compact
          >
            {error.download}
          </Message>
        )}
        <Button
          color="blue"
          onClick={handleDownloadClick}
          disabled={loadingDownload || _.isEmpty(selectedSallesIds)}
        >
          <Icon name="download" />
          Télécharger
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

DocumentsModal.propTypes = {
  container: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

DocumentsModal.defaultProps = {
  container: null,
};

export default DocumentsModal;
