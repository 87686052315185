import React from 'react';

import PropTypes from 'prop-types';

import SalleContextProvider from './SalleContextProvider';
import ReservationContextProvider from './ReservationContextProvider';
import ReservantContextProvider from './ReservantContextProvider';
import DataLoader from './DataLoader';
import SessionStorageUpdater from './SessionStorageUpdater';
import ParametreContextProvider from './ParametreContextProvider';

const DataProvider = ({ children }) => (
  <DataLoader>
    <ParametreContextProvider>
      <SalleContextProvider>
        <ReservationContextProvider>
          <ReservantContextProvider>
            <SessionStorageUpdater />
            {children}
          </ReservantContextProvider>
        </ReservationContextProvider>
      </SalleContextProvider>
    </ParametreContextProvider>
  </DataLoader>
);

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataProvider;
