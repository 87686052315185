export const getSallesByEntite = (
  baseUrl,
  identifiantEntite,
) => `${baseUrl}/api/salles/publication?perPage=100&idEntite=${identifiantEntite}`;

export const getSalles = (
  baseUrl,
  salles,
) => `${baseUrl}/api/salles/search?identifiant=${salles}&perPage=100`;

export const getSallesDisponibles = (
  baseUrl,
  identifiantEntite,
  identifiantsSalles,
  dateDebut,
  dateFin,
  typeSalle,
  typeReservant,
  activite,
  nbPersonnes,
  nbPlacesAssises,
  nbPlacesDebouts,
) => `${baseUrl}/api/salles/getSallesDisponibles?`
    + `identifiantsSalles=${identifiantsSalles}&`
    + `dateDebut=${dateDebut}&`
    + `dateFin=${dateFin}&`
    + `typeSalle=${typeSalle}&`
    + `typeReservant=${typeReservant}&`
    + `activite=${activite}&`
    + `nbPersonnes=${nbPersonnes}&`
    + `nbPlacesAssises=${nbPlacesAssises}&`
    + `nbPlacesDebouts=${nbPlacesDebouts}&`
    + `idEntite=${identifiantEntite}`;

export const getTypesSalle = (baseUrl) => `${baseUrl}/api/typesSalles?perPage=100`;

export const getTypesReservantSalle = (
  baseUrl,
  identifiantEntite,
) => `${baseUrl}/api/typeReservantSalles?perPage=200&idEntite=${identifiantEntite}`;
