import React from 'react';

import PropTypes from 'prop-types';

import {
  Grid,
} from '@jvs-group/jvs-mairistem-composants';

import DisponibiliteResultsCriteres from './DisponibiliteResultsCriteres';
import DisponibiliteResultsTable from './DisponibiliteResultsTable';

const DisponibiliteResults = (props) => {
  const {
    activite,
    capacite,
    debut,
    fin,
    loading,
    onSalleSelect,
    placesAssises,
    placesDebouts,
    salles,
    typeReservant,
    typeSalle,
    typesSalle,
  } = props;

  return (
    <Grid>
      <Grid.Row style={{ margin: '0 20px' }}>
        <Grid.Column>
          <DisponibiliteResultsCriteres
            activite={activite}
            capacite={capacite}
            debut={debut}
            fin={fin}
            placesAssises={placesAssises}
            placesDebouts={placesDebouts}
            typeReservant={typeReservant}
            typeSalle={typeSalle}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ margin: '0 20px' }}>
        <Grid.Column>
          <DisponibiliteResultsTable
            loading={loading}
            onSelect={onSalleSelect}
            salles={salles}
            typesSalle={typesSalle}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

DisponibiliteResults.propTypes = {
  activite: PropTypes.number,
  capacite: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // dayjs date
  // eslint-disable-next-line react/forbid-prop-types
  debut: PropTypes.object,
  // dayjs date
  // eslint-disable-next-line react/forbid-prop-types
  fin: PropTypes.object,
  placesAssises: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placesDebouts: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  typeReservant: PropTypes.number,
  typeSalle: PropTypes.number,
  loading: PropTypes.bool,
  onSalleSelect: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  salles: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  typesSalle: PropTypes.array,
};

DisponibiliteResults.defaultProps = {
  activite: null,
  capacite: null,
  debut: null,
  fin: null,
  placesAssises: null,
  placesDebouts: null,
  typeReservant: null,
  typeSalle: null,
  loading: null,
  onSalleSelect: null,
  salles: null,
  typesSalle: null,
};

export default DisponibiliteResults;
