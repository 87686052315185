import _ from 'lodash';
import React from 'react';

import PropTypes from 'prop-types';

import {
  Dimmer,
  Header,
  Icon,
  List,
} from '@jvs-group/jvs-mairistem-composants';

import HeaderLink from '../HeaderLink';
import Button from '../Button';

import { ApplicationContext, DataLoaderContext } from '../../context';
import DataLoaderItem from './DataLoaderItem';

const DataLoader = ({ children }) => {
  const [errors, setErrors] = React.useState({});
  const [detailErrors, setDetailErrors] = React.useState(false);
  const [salleContextLoadings, setSalleContextLoadings] = React.useState({
    loadingSalles: true,
    loadingTypes: true,
    loadingTypesReservantSalle: true,
  });
  const [reservantContextLoadings, setReservantContextLoadings] = React.useState({
    loadingTypesReservant: true,
    loadingReservants: true,
  });
  const [reservationContextLoadings, setReservationContextLoadings] = React.useState({
    loadingReservations: true,
    loadingStatuts: true,
    loadingTypesReservation: true,
  });
  const [parametreContextLoadings, setParametreContextLoadings] = React.useState({
    loadingParametres: true,
  });
  const [planningPreload, setPlanningPreload] = React.useState();

  const { webdev, loading: applicationLoading } = React.useContext(ApplicationContext);

  // Chargement du sessionStorage pour le preload
  // Pour réafficher plus rapidement le planning quand on revient d'une page webdev
  React.useEffect(() => {
    const preload = sessionStorage.getItem('planningPreload');

    if (!_.isNil(preload)) {
      setPlanningPreload(JSON.parse(preload));
    }
  }, []);

  const loading = React.useMemo(() => {
    const {
      loadingSalles,
      loadingTypes,
      loadingTypesReservantSalle,
    } = salleContextLoadings;
    const {
      loadingTypesReservant,
      loadingReservants,
    } = reservantContextLoadings;
    const {
      loadingReservations,
      loadingStatuts,
      loadingTypesReservation,
    } = reservationContextLoadings;
    const {
      loadingParametres,
    } = parametreContextLoadings;

    // Si les données de base n'ont pas fini de charger (baseUrl, entité...)
    if (applicationLoading) { return true; }

    // Si tout a fini de charger, plus besoin du loader
    if (!loadingSalles
        && !loadingTypes
        && !loadingTypesReservantSalle
        && !loadingTypesReservant
        && !loadingReservants
        && !loadingReservations
        && !loadingStatuts
        && !loadingTypesReservation
        && !loadingParametres
    ) {
      return false;
    }

    return true;
  }, [
    applicationLoading,
    salleContextLoadings,
    reservantContextLoadings,
    reservationContextLoadings,
    parametreContextLoadings,
  ]);

  const contextValue = React.useMemo(
    () => ({
      salleContextLoadings,
      reservantContextLoadings,
      reservationContextLoadings,
      setSalleContextLoadings,
      setReservantContextLoadings,
      setReservationContextLoadings,
      setParametreContextLoadings,
      fullyLoaded: !loading,
      planningPreload,
      setErrors,
    }),
    [
      salleContextLoadings,
      reservantContextLoadings,
      reservationContextLoadings,
      parametreContextLoadings,
      setSalleContextLoadings,
      setReservantContextLoadings,
      setReservationContextLoadings,
      setParametreContextLoadings,
      loading,
      planningPreload,
      setErrors,
    ],
  );

  if (!_.isEmpty(errors)) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '25rem',

        ...webdev && {
          paddingBottom: '10px', // sinon y'a un scroll
          justifyContent: 'flex-start',
        },
      }}
      >
        <List divided style={{ textAlign: 'left' }}>
          <List.Item>
            <List.Header as={Header} icon>
              <Icon name="bug" />
              Une erreur est survenue lors du chargement du planning
            </List.Header>
          </List.Item>
        </List>
        <Button
          compact
          onClick={() => setDetailErrors((prev) => !prev)}
          basic
          size="mini"
          style={{ width: 100 }}
        >
          {detailErrors ? 'cacher détail' : 'voir détail'}
        </Button>
        {detailErrors && (
        <List style={{ textAlign: 'left' }}>
          {_.map(errors, (value) => (
            <List.Item>
              <List.Icon name="cancel" color="red" />
              <List.Content verticalAlign="middle">
                <List.Header>{value}</List.Header>
              </List.Content>
            </List.Item>
          ))}
        </List>
        )}
      </div>
    );
  }

  return (
    <>
      {!applicationLoading && (
        <DataLoaderContext.Provider value={contextValue}>
            {children}
        </DataLoaderContext.Provider>
      )}
      <Dimmer active={loading} inverted style={{ ...webdev && { paddingTop: '25rem', justifyContent: 'flex-start' } }}>
        <List divided style={{ textAlign: 'left', marginBottom: 30 }}>
          <List.Item>
            <List.Header as={HeaderLink} icon>
              <Icon name="calendar alternate" />
              Chargement du planning
            </List.Header>
          </List.Item>
        </List>
        <List style={{ textAlign: 'left', marginLeft: 75 }}>
          <DataLoaderItem loading={applicationLoading
            && parametreContextLoadings.loadingParametres}
          >
            Chargement des paramètres
          </DataLoaderItem>
          <DataLoaderItem loading={salleContextLoadings.loadingTypes}>
            Chargement des types de salle
          </DataLoaderItem>
          <DataLoaderItem loading={salleContextLoadings.loadingTypesReservantSalle}>
            Chargement des types de réservant des salles
          </DataLoaderItem>
          <DataLoaderItem loading={salleContextLoadings.loadingSalles}>
            Chargement des salles
          </DataLoaderItem>
          <DataLoaderItem loading={reservantContextLoadings.loadingTypesReservant}>
            Chargement des types de réservant
          </DataLoaderItem>
          <DataLoaderItem loading={reservantContextLoadings.loadingReservants}>
            Chargement des réservants
          </DataLoaderItem>
          <DataLoaderItem loading={reservationContextLoadings.loadingReservations}>
            Chargement des réservations
          </DataLoaderItem>
          <DataLoaderItem loading={reservationContextLoadings.loadingStatuts}>
            Chargement des statuts
          </DataLoaderItem>
          <DataLoaderItem loading={reservationContextLoadings.loadingTypesReservation}>
            Chargement des activités
          </DataLoaderItem>
        </List>
      </Dimmer>
    </>
  );
};

DataLoader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DataLoader;
