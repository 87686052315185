import React from 'react';

const PlanningContext = React.createContext({
  view: null,
  viewHandlers: {
    changeToView: null,
    changeToMode: null,
  },
  mode: null,
  reservationsOfView: [],
  reservationHandlers: {
    goTo: null,
  },
  currentDate: null,
  dateHandlers: {
    goTo: null,
    goForward: null,
    goBackward: null,
  },
  selectedSalles: [],
  selectedSallesHandlers: {
    selectSelection: null,
  },
});

export default PlanningContext;
