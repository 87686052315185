import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import {
  Modal,
} from '@jvs-group/jvs-mairistem-composants';

import {
  PlanningContext,
  SalleContext,
} from '../../context';

import DisponibiliteResultsTable from '../Disponiblite/DisponibiliteResultsTable';

const ReserverModal = ({ onClose }) => {
  const {
    reservationHandlers: {
      create: handleReservationCreate,
    },
  } = React.useContext(PlanningContext);

  const { salles, typesSalle } = React.useContext(SalleContext);

  const handleSalleSelect = React.useCallback((idSalle) => {
    const salle = _.find(salles, { identifiant: idSalle });

    handleReservationCreate(null, '', salle, true);

    if (onClose) {
      onClose();
    }
  }, [
    salles,
    handleReservationCreate,
    onClose,
  ]);

  return (
    <Modal
      open
      onClose={onClose}
      closeIcon
    >
      <Modal.Header>
        Réserver une salle
      </Modal.Header>
      <Modal.Content scrolling={false}>
        <DisponibiliteResultsTable
          loading={false}
          onSelect={handleSalleSelect}
          salles={salles}
          selectMessage={false}
          typesSalle={typesSalle}
        />
      </Modal.Content>
    </Modal>
  );
};

ReserverModal.propTypes = {
  onClose: PropTypes.func,
};

ReserverModal.defaultProps = {
  onClose: null,
};

export default ReserverModal;
