/* eslint-disable max-len */
import ReservationColorType from '../const/reservationColorType';
import TypeReservant from '../const/typeReservant';

/**
 * On renvoie le texte du tooltip au survol de la pastille du créneau selon le paramétrage en place
 *
 * @param {int} colorType Le type de paramétrage de couleur : par statuts, réservants ou activités
 * @param {int} typeReservant Le type de réservation : administration, association, entreprise ou particulier
 * @param {string} libelleStatut Le libellé du statut de la réservation : "en attente", "validée", "refusée", ... etc
 * @param {string} libelleTypeReservation Le libellé du type de réservation : "kermess", "concert", "loto", ... etc
 *
 * @returns {string} Le texte du tooltip à afficher au survol
 */
const getReservationTooltipText = (
  colorType,
  typeReservant,
  libelleStatut,
  libelleTypeReservation,
) => {
  switch (colorType) {
    case ReservationColorType.STATUT:
      return libelleStatut;

    case ReservationColorType.RESERVANT:
      switch (typeReservant) {
        case TypeReservant.ADMINISTRATION:
          return 'Administration';
        case TypeReservant.ASSOCIATION:
          return 'Association';
        case TypeReservant.ENTREPRISE:
          return 'Entreprise';
        case TypeReservant.PARTICULIER:
          return 'Particulier';
        default:
          return '';
      }

    case ReservationColorType.ACTIVITE:
      return libelleTypeReservation;

    default:
      return '';
  }
};

export default getReservationTooltipText;
